// dateUtils.js

// Custom function to format the date as "24th March, 1982"
export function prettyDate(date) {
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    // Return a fallback if the date is invalid
    return "-";
  }
  
  const day = d.getDate();
  const month = getMonthName(d.getMonth());
  const year = d.getFullYear();
  const daySuffix = getDaySuffix(day);
  return `${day}${daySuffix} ${month} ${year}`;
}

// Custom function to get the month name
function getMonthName(month) {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return monthNames[month];
}

// Custom function to get the day suffix (e.g., "st", "nd", "rd", "th")
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

  
  