import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, FlatList, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";

import ListItem from "../components/ListItem";
import GridItem from "../components/gridItem";
import { VibrateOnce } from "../functions/vibration";

const Badges = ({ route }) => {
  const { badges } = route.params;
  const { width } = useWindowDimensions();

  const [numberOfColumnsForBadges, setNumberOfColumnsForBadges] = useState(3);
  const colorScheme = useColorScheme();
  const [isPressing, setIsPressing] = useState(false); // State to track press status

  console.log('badges',badges)
// get the full date of the last 1st September 
  const getAcademicYearStartDate = () => {
    const currentDate = new Date();
    const academicYearStart = new Date(currentDate.getFullYear(), 8, 1); // Assuming academic year starts on September 1st
    if (currentDate < academicYearStart) {
      // If current date is before September 1st, consider the previous academic year
      academicYearStart.setFullYear(currentDate.getFullYear() - 1);
    }
    return academicYearStart;
  };
  

  const academicYearStartDate = getAcademicYearStartDate();

  const filteredBadges = badges.filter((badge) => {
    const dateAwarded = new Date(badge.dateAwarded);
    console.log('badges',badges)
    return dateAwarded >= academicYearStartDate && badge.scoringData3Value > 0;
  });

  const [view, setView] = useState(filteredBadges.length>0?"grid":"list");

  const toggleView = () => {
    VibrateOnce()
    if (view === "list") {
      setView("grid");
    } else {
      setView("list");
    }
  };

  useEffect(() => {
    let columnsForBadges = 1;

    if (width < 375) {
      columnsForBadges = 1;
    } else if (width < 400) {
      columnsForBadges = 2;
    } else if (width < 600) {
      columnsForBadges = 3;
    } else if (width < 800) {
      columnsForBadges = 4;
    } else if (width < 990) {
      columnsForBadges = 5;
    } else {
      columnsForBadges = 6;
    }

    setNumberOfColumnsForBadges(columnsForBadges);
  }, [width]);

  const sortedBadges = badges.filter((badge) => {
    
    return badge.scoringData3Value > 0;
  }).sort((badgeA, badgeB) => {
    // Convert dateAwarded to JavaScript Date objects for comparison
    const dateA = new Date(badgeA.dateAwarded);
    const dateB = new Date(badgeB.dateAwarded);

    // Compare the dates in reverse chronological order
    return dateB - dateA;
  });






  return (
    <View style={{backgroundColor: Colours[colorScheme].background, flex:1}}>
    <View style={{ backgroundColor: Colours[colorScheme].almostBackground, borderRadius:10,alignItems: "center", flex: 1, width: "100%" }}>


<View style={{backgroundColor: Colours[colorScheme].practicallyBackground, 
            alignItems: "flex-end",
            width: "100%",
            padding: 5,}}> 

      {/* view toggle button */}
      <Pressable onPress={toggleView} 
      
      onPressIn={() => setIsPressing(true)} // Set isPressing to true on press in
        onPressOut={() => setIsPressing(false)} // Set isPressing to false on press out
      
      
        style={({ pressed }) => [
          {
            backgroundColor: pressed || isPressing ? Colours[colorScheme].edging : Colours[colorScheme].practicallyBackground,
            borderRadius:10, width:35, height: 35, alignItems:'center', justifyContent:'center'
          },
        ]}
        
        // backgroundColor:Colours[colorScheme].background, alignItems: "flex-end", width: "100%", padding: 10 }}
        >
        
          {view == "grid" ? <Feather name="list" size={30} color={Colours.palette.secondaryGreen} /> : <Feather name="grid" size={30} color={Colours.palette.secondaryGreen} />}
        
      </Pressable>
      </View>
      {view == "list" ? (
        <FlatList
          data={sortedBadges}
          contentContainerStyle={{ 
            // maxWidth: 800, 
            width:'100%',
            alignSelf: "center" }} //positions the flatlist in the centre of the parent view
          style={{ width: "100%" }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
          key={"list"}
          keyExtractor={(item) => item.id}
          // scrollEnabled={false}
          renderItem={({ item }) => <ListItem item={item} />}
        />
      ) : (
        <FlatList
          data={filteredBadges}
          contentContainerStyle={{ alignItems: "center" }} //positions the flatlist in the centre of the parent view
          style={{ width: "100%" }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
          key={numberOfColumnsForBadges}
          keyExtractor={(item) => item.id}
          numColumns={numberOfColumnsForBadges}
          ListFooterComponent={<Pressable onPress={toggleView} style={({ pressed }) =>[{backgroundColor: pressed ? Colours[colorScheme].edging : 'transparent',borderRadius:10, padding:5, alignItems:'center', justifyContent:'center', margin:5}]}><Text style={{color:Colours[colorScheme].textLessEmphasised, fontSize:9, textAlign:'center'}}>Displaying badges for this academic year.{'\n'}Tap here to see all.</Text></Pressable>} // Add the footer component here
          renderItem={({ item }) => <GridItem item={item} />}
        />
      )}
    </View>
    </View>
  );
};

export default Badges;
