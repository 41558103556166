import { API, graphqlOperation } from 'aws-amplify';
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 20000000, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const fetchUsersFromDatabase = async () => {
  console.log('getAllusers fired')
  try {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          isActive: { eq: true }, // Filter active users only
        },
      })
    );

    if (response.data && response.data.listUsers.items) {
      return response.data.listUsers.items; // Return the list of users
    } else {
      throw new Error('No users found');
    }
  } catch (err) {
    console.error('Error fetching users:', err);
    throw err; // Rethrow the error for handling in the main component
  }
};
