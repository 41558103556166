import React from "react";
import Svg, { Path } from "react-native-svg";

const InfluenceIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M378.1,205.5h-0.8h-23.8h-24.6l0,0H303l7-21.3c6-16,9-26.7,9-31.9c0-11-3.5-21.5-10-30.3
	c-4.5-6.1-11.4-9.7-19-9.8c-7.6-0.1-14.7,3.1-19.4,9L237,163.8l-52.9,41.7l-27.5,0.1L89.7,369.8h99.8c9.4,8,21.5,12.6,34.5,12.6
	h90.4h17.1h0.9l0,0c13.1-0.4,24.2-8.8,28.6-20.4l0.2,0.1l46.3-113.6l0.1-0.3c1.4-3.6,2.1-7.3,2.1-11.2
	C409.6,219.6,395.5,205.5,378.1,205.5z M362.1,268.5c3.5,0,6.5,3,6.5,6.5s-3,6.5-6.5,6.5h-15.8h-17.5c-3.5,0-6.5-3-6.5-6.5
	s3-6.5,6.5-6.5h21.6h3l0,0H362.1z M322.4,312.9c0-3.5,3-6.5,6.5-6.5h17.5c3.5,0,6.5,3,6.5,6.5s-3,6.5-6.5,6.5h-14.9h-2.6
	C325.3,319.4,322.4,316.4,322.4,312.9z M383.8,237c0,3.5-3,6.5-6.5,6.5h-15.1l0,0h-11.7h-21.6c-3.5,0-6.5-3-6.5-6.5s3-6.5,6.5-6.5
	h42.7l0,0h5.7C380.8,230.5,383.8,233.4,383.8,237z M197,337.8l-6.6-20.2l-23.8,7.7l6.3,19.4h-46.2l46.6-114.3l19.4-0.1l62-48.9
	l34.6-44c2.9,4.3,4.5,9.2,4.5,14.4c-0.4,2.9-3.7,13.4-7.5,23.6l-18.1,54.8H298c-0.4,2.1-0.7,4.3-0.7,6.5c0,7.1,2.4,13.7,6.4,19
	c-4,5.3-6.4,11.9-6.4,19s2.4,13.7,6.4,19c-4,5.3-6.4,11.9-6.4,19c0,3.7,0.6,7.2,1.8,10.4c-9.7,5.4-16.2,15.7-16.2,27.5
	c0,2.2,0.2,4.4,0.7,6.5h-45l0,0H224l0,0C211.7,357.4,200.8,349.5,197,337.8z M331.5,357.4h-17.1c-3.5,0-6.5-3-6.5-6.5s3-6.5,6.5-6.5
	h14.5h2.6c3.5,0,6.5,3,6.5,6.5S335,357.4,331.5,357.4z M187,296.2h-5.8v-25h5.8c46.7,0,57.8,0,74.2-25.4l21,13.6
	C258.4,296.2,234.5,296.2,187,296.2z"fill={'#56AFF1'}/>

	

    
    </Svg>
    
        
  );
};

export default InfluenceIcon;