import React from "react";
import { View, Text } from "../constants/themedViewAndText";
import { StyleSheet, useWindowDimensions, ScrollView, Pressable, Alert, Platform } from "react-native";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { VibrateOnce } from "../functions/vibration";

const WeekdayGrid = ({ startDate, endDate, exceptionalDays, successfulDays, clubAttendance }) => {
  const { width: screenWidth } = useWindowDimensions();
  const colorScheme = useColorScheme();
  const weeks = getWeeksBetweenDates(startDate, endDate);
  const squaresPerRow = calculateSquaresPerRow(screenWidth);
  const squareSize = calculateSquareSize(screenWidth, squaresPerRow);
  const showPopUp = (selectedDate) => {
    const formattedDate = selectedDate.toLocaleDateString("en-GB", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    // Find clubs attended on the selected date
    const clubsAttended = clubAttendance.filter((item) => item.recordedDate === selectedDate.toISOString().split("T")[0]);

    // Generate the alert message
    let alertMessage = `${formattedDate}\n`;

    // If there are clubs attended, include their names in the message
    if (clubsAttended.length > 0) {
      VibrateOnce();
      const clubNames = clubsAttended
      .map((item) => item?.Opportunity?.name ?? "Unnamed Club")
      .join(", ");
    
      alertMessage += `\n ✅ ${clubNames}`;

      if (Platform.OS === "web") {
        alert(alertMessage);
      } else {
        Alert.alert("Flexible Learning Attendance", alertMessage);
      }
    }
  };

  return (
    <ScrollView
      horizontal
      contentContainerStyle={{
        justifyContent: "flex-start",
        backgroundColor: "transparent",
        // flex:1,
        alignItems: "center",
      }}>
      <View style={styles.container}>
        <View style={{ margin: 2, flexDirection: "column", justifyContent: "space-between", backgroundColor: "transparent",alignItems:'center',marginRight:5 }}>
          <Text style={{fontSize:14, color:Colours[colorScheme].textLessEmphasised}}>Mon</Text>
          <Text style={{fontSize:14, color:Colours[colorScheme].textLessEmphasised}}>Tue</Text>
          <Text style={{fontSize:14, color:Colours[colorScheme].textLessEmphasised}}>Wed</Text>
          <Text style={{fontSize:14, color:Colours[colorScheme].textLessEmphasised}}>Thu</Text>
          <Text style={{fontSize:14, color:Colours[colorScheme].textLessEmphasised}}>Fri</Text>
        </View>
        {weeks.map((week, weekIndex) => (
          <View key={weekIndex} style={[styles.week, { backgroundColor: Colours[colorScheme].almostBackground }]}>
            {week.map((day, dayIndex) => (
              <Pressable key={`pressable-${weekIndex}-${dayIndex}`} onPress={() => showPopUp(day)}>
                <View
                  key={`view-${weekIndex}-${dayIndex}`}
                  style={[
                    styles.day,
                    { width: squareSize, height: squareSize, backgroundColor: Colours[colorScheme].background },
                    exceptionalDays.includes(day.toISOString().split("T")[0]) && styles.exceptionalDay,
                    successfulDays.includes(day.toISOString().split("T")[0]) && styles.successfulDay,
                  ]}></View>
              </Pressable>
            ))}
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

const calculateSquaresPerRow = (screenWidth) => {
  const minSquareSize = 20;
  return Math.floor(screenWidth / minSquareSize);
};

const calculateSquareSize = (screenWidth, squaresPerRow) => {
  return screenWidth / squaresPerRow;
};

const getWeeksBetweenDates = (startDate, endDate) => {
  const weeks = [];
  let currentDate = new Date(startDate);

  // Find the nearest Monday
  while (currentDate.getDay() !== 1) {
    currentDate.setDate(currentDate.getDate() - 1);
  }

  while (currentDate <= endDate) {
    const daysInWeek = getDaysInWeek(currentDate);
    weeks.push(daysInWeek);
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return weeks;
};

const getDaysInWeek = (startDate) => {
  const days = [];
  let currentDate = new Date(startDate);

  // Iterate through 5 weekdays
  for (let i = 0; i < 5; i++) {
    // Check if the current day is a weekday (Monday-Friday)
    while (currentDate.getDay() < 1 || currentDate.getDay() > 5) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    days.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return days;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: 15,
    marginTop: 10,
    backgroundColor: "transparent",
  },
  dayLabel: {
    fontSize: 8,
  },
  week: {
    flexDirection: "column",
    // backgroundColor:'red'
  },
  day: {
    margin: 2,
    borderRadius: 3,
  },
  exceptionalDay: {
    backgroundColor: "transparent",
  },
  successfulDay: {
    backgroundColor: Colours.palette.logoGreen,
  },
});

export default WeekdayGrid;
