import React from "react";
import Svg, { Path } from "react-native-svg";

const CourageIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M115.4,313.3l38.9-20.7l-21.4,38.5L115.4,313.3z M366.3,331.1l17.5-17.8l-38.9-20.7L366.3,331.1z M403,256.8
	c-1.9,4.7-6.5,7.8-11.6,7.8h-84.8l22.5,76.3l-12,16h-33.5l-12.4,39.9H228L215.6,357h-33.5l-12-16l22.5-76.3h-84.8
	c-5.1,0-9.7-3.1-11.6-7.8c-1.9-4.7-0.7-10.1,2.9-13.7L241,106.8c4.8-4.7,12.5-4.7,17.3,0l141.8,136.3
	C403.7,246.6,404.9,252.1,403,256.8z M226.1,239.6h47L249.6,160L226.1,239.6z M200,239.6l24.2-82.1l-85.4,82.1H200z M257.4,357
	h-15.7l4.6,14.9h6.4L257.4,357z M300.4,332l-19.9-67.4h-61.8L198.8,332h2.8l-0.5-0.6l20-16h56.1l20,16l-0.5,0.6H300.4z M360.4,239.6
	L275,157.5l24.2,82.1H360.4z M249.2,274.7c-9,0-16.4,7.3-16.4,16.4c0,9,7.3,16.4,16.4,16.4c9,0,16.4-7.3,16.4-16.4
	S258.2,274.7,249.2,274.7z" fill={'#E02A06'}/>

	

    
    </Svg>
    
        
  );
};

export default CourageIcon;