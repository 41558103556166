import React from 'react';
import { TextInput, Platform } from 'react-native';
import Colours from '../constants/colours';
import useColorScheme from '../hooks/useColorScheme';
import { View, Text } from "../constants/themedViewAndText";
interface RenderTextInputProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

const RenderMultiLineTextInput: React.FC<RenderTextInputProps> = ({ label, value, onChange }) => {
  const colorScheme = useColorScheme();

  return (
    <View style={{ backgroundColor: 'transparent', flexDirection: 'row', alignItems: 'center' }}>
      <View style={{ backgroundColor: 'transparent', width: '50%', alignItems: 'flex-end' }}>
        <Text>{label}:</Text>
      </View>
      <View style={{ backgroundColor: 'transparent', width: '50%' }}>
        <View
          style={{
            padding: 10,
            margin: 10,
            flexDirection: 'row',
            backgroundColor: Colours[colorScheme].almostBackground,
            borderColor: Colours.palette.logoGreen,
            borderWidth: 1,
            borderRadius: 8,
            alignItems: 'center',
          }}
        >
          <TextInput
            style={[
              Platform.OS === 'web' ? { outlineWidth: 0 } : null,
              {
                width: '100%',
                color: Colours[colorScheme].text,
                fontSize: 12,
                fontFamily: 'avenir-next',
                minHeight:100
              },
            ]}
            value={value}
            multiline={true}
            numberOfLines={4}
            onChangeText={onChange}
            placeholderTextColor={Colours[colorScheme].textLessEmphasised}
          />
        </View>
      </View>
    </View>
  );
};

export default RenderMultiLineTextInput;