// getCurrentTerm.js
export const getCurrentTerm = (specialDates) => {
    const StartOfAutumn1TermString = specialDates.find(date => date.specialDateType === "StartOfAutumn1Term")?.specialDate;
    const StartOfAutumn2TermString = specialDates.find(date => date.specialDateType === "StartOfAutumn2Term")?.specialDate;
    const StartOfSpring1TermString = specialDates.find(date => date.specialDateType === "StartOfSpring1Term")?.specialDate;
    const StartOfSpring2TermString = specialDates.find(date => date.specialDateType === "StartOfSpring2Term")?.specialDate;
    const StartOfSummer1TermString = specialDates.find(date => date.specialDateType === "StartOfSummer1Term")?.specialDate;
    const StartOfSummer2TermString = specialDates.find(date => date.specialDateType === "StartOfSummer2Term")?.specialDate;
  
    const StartOfAutumn1TermDate = StartOfAutumn1TermString ? new Date(StartOfAutumn1TermString) : null;
    const StartOfAutumn2TermDate = StartOfAutumn2TermString ? new Date(StartOfAutumn2TermString) : null;
    const StartOfSpring1TermDate = StartOfSpring1TermString ? new Date(StartOfSpring1TermString) : null;
    const StartOfSpring2TermDate = StartOfSpring2TermString ? new Date(StartOfSpring2TermString) : null;
    const StartOfSummer1TermDate = StartOfSummer1TermString ? new Date(StartOfSummer1TermString) : null;
    const StartOfSummer2TermDate = StartOfSummer2TermString ? new Date(StartOfSummer2TermString) : null;
  
    const today = new Date();
  
    if (StartOfAutumn1TermDate && StartOfAutumn2TermDate && today >= StartOfAutumn1TermDate && today < StartOfAutumn2TermDate) {
      return "Autumn 1";
    } else if (StartOfAutumn2TermDate && StartOfSpring1TermDate && today >= StartOfAutumn2TermDate && today < StartOfSpring1TermDate) {
      return "Autumn 2";
    } else if (StartOfSpring1TermDate && StartOfSpring2TermDate && today >= StartOfSpring1TermDate && today < StartOfSpring2TermDate) {
      return "Spring 1";
    } else if (StartOfSpring2TermDate && StartOfSummer1TermDate && today >= StartOfSpring2TermDate && today < StartOfSummer1TermDate) {
      return "Spring 2";
    } else if (StartOfSummer1TermDate && StartOfSummer2TermDate && today >= StartOfSummer1TermDate && today < StartOfSummer2TermDate) {
      return "Summer 1";
    } else if (StartOfSummer2TermDate && today >= StartOfSummer2TermDate) {
      return "Summer 2";
    } else {
      return "Autumn 1";
    }
  };
  