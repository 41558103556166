import React from "react";
import Svg, { Path } from "react-native-svg";

const InitiativeIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M279.6,174.2l-19.1,58.6h30.7L218.5,286l19.1-58.6h-30.7L279.6,174.2z M356.9,229.9c-0.1-13.6-2.7-27-7.8-39.6
	c-0.3-0.8-0.6-1.5-0.9-2.2c-6.8-15.8-17.5-30-30.8-40.9c-0.8-0.7-1.7-1.4-2.5-2c-70.2-54.6-173.8-3.6-173.6,85.4
	c-0.5,41.2,25,80.4,62,97.3l7.5-23l0.2-0.7c-76.4-40.1-48.2-155.8,38.1-156.4c93.2,1.3,114.4,130.3,26.6,161.3l-64,22.4l0,0L176,344
	l8.3,23.6c20.4-7.2,82-28.5,101.9-36c16.1-6.1,28.2-14,40-25.9c0.1-0.1,0.2-0.2,0.3-0.3c1-1.1,2-2.1,3-3.2
	c10.3-11.4,18-25.1,22.6-39.8c1.6-5.2,2.9-10.6,3.7-16c0.5-3.3,0.8-6.5,1-9.8c0.1-1.9,0.2-3.8,0.2-5.7
	C356.9,230.5,356.9,230.1,356.9,229.9z M111.5,294.9l12.4,21.7l17.5-10.1L129,284.8L111.5,294.9z M385.6,166.5l-12.4-21.7l-16.8,9.6
	l12.4,21.7L385.6,166.5z M356.7,306l16.6,9.5l12.4-21.7l-16.6-9.5L356.7,306z M141.9,153.9l-17.7-10.2l-12.4,21.7l17.7,10.2
	L141.9,153.9z M380,217.6v25h38.5v-25H380z M118,217.6H80.7v25H118V217.6z M309.9,339.1l-128.8,45.1l4.1,24.3h123.2v-25h-49.6
	l59.4-20.8L309.9,339.1z"fill={'#FDE277'}/>

	

    
    </Svg>
    
        
  );
};

export default InitiativeIcon;