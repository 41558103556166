import { useState, useEffect } from "react";
import { Pressable, StyleSheet, Text, View, ActivityIndicator } from "react-native";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react-native";
import React from "react";
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import { API, graphqlOperation } from "aws-amplify";
import { UserContext, AllUsersContext, OrganisationContext } from "../hooks/Contexts";
import HomeScreen from "../screens/homeRoot";
import MyTabs from "../navigation/tabs";
import ActivityIndicatorAnimation from "./ActivityIndicator";
import { Feather } from "@expo/vector-icons";
import ActionButton from "./ActionButton";
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 20000000, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      aaa
      logoURL
      additionalGraphicURL
      additionalFileURL
      type
      currentAcademicYear
 SpecialDates {
        items {
          id
          academicYear
          specialDate
          specialDateType
        }
        
      }

      contextDefinition1
      contextDefinition2
      contextDefinition3
      groupingDefinition1
      groupingDefinition2
      groupingDefinition3
      scoringDefinition1
      scoringDefinition2
      scoringDefinition3
      Enrolments {
        nextToken
        startedAt
        __typename
      }
      OpportunityCategories {
        nextToken
        startedAt
        __typename
      }
      Media {
        nextToken
        startedAt
        __typename
      }
      DailyPointsTotals {
        nextToken
        startedAt
        __typename
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

const AuthenticationCheck = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [userDataFromCognito, setUserDataFromCognito] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [matchedUser, setMatchedUser] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const colorScheme = useColorScheme();
const [organisationData, setOrganisationData] = useState()

  const fetchOrganisationData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getOrganisation, { id: 'e5b5c6c5-9a99-45cf-9bb2-c94764a6ca6c' }));

      if (response && response.data && response.data.getOrganisation) {


        console.log('ORGANISATION DATA', response.data.getOrganisation)
        setOrganisationData(response.data.getOrganisation);
        
      
        
      } else {
        // Handle the case when the response is empty or doesn't contain the expected data
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } 
  };

  useEffect(() => {
    fetchOrganisationData();
  }, []);






  
const FLbooket = organisationData?organisationData.additionalFileURL:'no data'
const FLbookletImage = organisationData?organisationData.additionalGraphicURL:'no data'
const currentAcademicYearFromServer = organisationData?organisationData.currentAcademicYear:'no data'

// Get all special dates
const specialDatesFromServer = organisationData?.SpecialDates?.items || [];









  const carrManorData = {
    organisationName: "Carr Manor Community School",
    organisationID: "e5b5c6c5-9a99-45cf-9bb2-c94764a6ca6c",
    currentAcademicYear: currentAcademicYearFromServer,
    currentAcademicYearStartDate: "2024-09-01",
    currentAcademicYearEndDate: "2025-08-31",
    // currentAcademicYear: "2023-2024",
    // currentAcademicYearStartDate: "2023-09-01",
    // currentAcademicYearEndDate: "2024-08-31",
    additionalFileURL: FLbooket,
    additionalGraphicURL: FLbookletImage,
    specialDates: specialDatesFromServer,
    // closedDates:closedDates
//     closedDates:[


// '2022-09-05',
// '2022-10-07',
// '2022-10-24',
// '2022-10-25',
// '2022-10-26',
// '2022-10-27',
// '2022-10-28',
// '2022-11-25',





// '2023-01-02',
// '2023-01-27',

// '2023-02-13',
// '2023-02-14',
// '2023-02-15',
// '2023-02-16',
// '2023-02-17',


// '2023-04-03',
// '2023-04-04',
// '2023-04-05',
// '2023-04-06',
// '2023-04-07',
// '2023-04-10',
// '2023-04-11',
// '2023-04-12',
// '2023-04-13',
// '2023-04-14',

// '2023-05-01',
// '2023-05-29',

// '2023-05-30',
// '2023-05-31',
// '2023-06-01',
// '2023-06-02',
//       '2023-06-26',
// '2023-07-24',
// '2023-07-25',
// '2023-07-26',
// '2023-07-27',
// '2023-07-28',
// '2023-07-31',

//       '2023-08-01',
//   '2023-08-02',
//   '2023-08-03',
//   '2023-08-04',
//   '2023-08-05',
//   '2023-08-06',
//   '2023-08-07',
//   '2023-08-08',
//   '2023-08-09',
//   '2023-08-10',
//   '2023-08-11',
//   '2023-08-12',
//   '2023-08-13',
//   '2023-08-14',
//   '2023-08-15',
//   '2023-08-16',
//   '2023-08-17',
//   '2023-08-18',
//   '2023-08-19',
//   '2023-08-20',
//   '2023-08-21',
//   '2023-08-22',
//   '2023-08-23',
//   '2023-08-24',
//   '2023-08-25',
//   '2023-08-26',
//   '2023-08-27',
//   '2023-08-28',
//   '2023-08-29',
//   '2023-08-30',
//   '2023-08-31',
  
//       '2023-09-01',
//       '2023-09-04',
//       '2023-10-06',
//       '2023-10-30',
//       '2023-11-01',
//       '2023-11-02',
//       '2023-11-03',
//       '2023-12-01',
//       '2023-10-31',
//       '2023-12-25',
//       '2023-12-26',
//       '2023-12-27',
//       '2023-12-28', 
//       '2023-12-29',
//       '2024-02-12',
//       '2024-02-13',
//       '2024-02-14',
//       '2024-02-15',
//       '2024-02-16',
//       '2024-03-08',
//       '2024-03-29',





//     ]

  };

  useEffect(() => {
    // Check if user data is available from useAuthenticator hook
    if (user) {
    

      const fetchUserDataFromDatabase = async () => {
        if (user.attributes?.email.includes("@carrmanor.org.uk")) {
          //does this user have a working Carr Manor account?

          try {
            const response = await API.graphql(
              graphqlOperation(listUsers, {
                filter: {
                  isActive: { eq: true },
                },
              })
            );


            if (response.data.listUsers.items.length > 0) {
 
          
              const userList = response.data.listUsers.items;
              const authenticatedUserEmail = user.attributes?.email;
              // const authenticatedUserEmail = "kayf01@carrmanor.org.uk"
              const authenticatedUser = userList.find(user => user.email === authenticatedUserEmail);
              if (authenticatedUser) {
                // You found the authenticated user, and you can access their details
                console.log("Authenticated User:", authenticatedUser);


               

              } else {
                console.log("Authenticated user not found in the response");
              }

              setAllUsers(userList);

              if (user && authenticatedUser && response && allUsers) {
          

                // const matchingUser = response.data.listUsers.items.find((item) => item.email === 'readt01@carrmanor.org.uk');

                // console.log(response.data.listUsers.items.find((item)=> item.email === 'readt01@carrmanor.org.uk'))

                //this doesn't work for pupil accounts and I don't know why!!!!!

                // console.log("matching user:", matchingUser);
                // console.log("matching user type", matchingUser.type);
                // setOrganisation(matchingUser.) STILL TO DO - WE NEED THE ENROLMENTS SORTED FOR STAFF SO WE CAN ATTACH THEM TO AN ORGANISATION (AND A HOUSE!) THEN RUN ANOTHER CHECK TO SEE IF THEY ARE CARR MANOR
                setAccountType(authenticatedUser.type);
                setMatchedUser(authenticatedUser);
              }
            }
          } catch (error) {
            console.error("Error fetching user data from the database", error);
          }
        } else {
          console.error('You must be a Carr Manor user"');
        }
      };

      fetchUserDataFromDatabase();
    } else {
      console.log('There is no authenticated user')
    }
  }, [user]);

  if (matchedUser && allUsers && (accountType === "STAFF" || accountType === "ADMIN") && user.attributes?.email.includes("@carrmanor.org.uk")) {
    return (
      <UserContext.Provider value={matchedUser}>
        <AllUsersContext.Provider value={allUsers}>
          <OrganisationContext.Provider value={carrManorData}>
            <MyTabs />
          </OrganisationContext.Provider>
        </AllUsersContext.Provider>
      </UserContext.Provider>
    );
  } else if (matchedUser && allUsers && accountType === "CHILD") {
    return (
      <>
      <View style={{ alignSelf:'center', alignContent: "center", justifyContent: "center", flex: 1 }}>
        <Text>Pupil accounts for Recognition are coming soon...</Text>
      </View>

<ActionButton text={"Sign out"} icon={"log-out"} onPressFunction={signOut}
 />
      
      </>
    );
  } else
    return (
      <>
      <View style={{ backgroundColor:Colours[colorScheme].background, alignItems: "center", justifyContent: 'space-evenly', flex: 1 }}>
        <ActivityIndicatorAnimation />
        <ActionButton text={"Sign out"} icon={"log-out"} onPressFunction={signOut}
 />
        
      </View>
     
      </>
    );
};

export default AuthenticationCheck;

const styles = StyleSheet.create({});
