import React from "react";
import Svg, { Path } from "react-native-svg";

const SelfAwarenessIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M375.4,139.2c-15.6-15.8-36.3-24.6-58.4-25c-0.6,0-49.9,0-84,0.1c-0.6,0-1.1,0-1.7,0c-1.9,0-3.7,0-5.6,0.1
	c-0.1,0-0.1,0-0.2,0l0,0c-23.8,0.8-47,9.1-65.8,23.7c-19.9,15.5-34.2,37.3-40.4,61.6l-18.9,62c-3.2,10.4,0.7,21.6,9.5,27.8l5.9,4.2
	v24.5c0,34.4,28,62.3,62.3,62.3h128.6h12.5v-12.1l2.4-35.4c0.1-1,0.6-7.1,0.6-7.1l-24.9-2c0,0-0.5,6.4-0.6,7.4l-1.7,24.2H178.2
	c-20.6,0-37.3-16.7-37.3-37.3v-37.4L124.5,269c-0.1,0-0.1-0.1-0.1-0.2l18.9-62.3l0.2-0.6c4.8-19,16-36.1,31.6-48.3
	c14.8-11.5,32.3-17.8,50.9-18.5c1.9,0,4,0,6.1,0c47,1.5,85.3,40.5,85.7,87.7c0.1,10.5-1.6,20.7-5.1,30.5c-3.4,9.4-6.2,18.9-8.6,28.3
	h-24.9c-12.6,0-22.9-10.3-22.9-22.9v-28.5l-11.1-7.9l12.7-41.9l0.2-0.6c1.7-6.7,4.5-12.9,8.3-18.6l-20.7-14
	c-5.4,8-9.4,16.8-11.8,26.2l-13.4,44c-2.7,8.9,0.5,18.4,8.1,23.8l2.6,1.9v15.6c0,26.4,21.5,47.9,47.9,47.9h91.4H383v-12.1l1.7-25.1
	c1-15.1,4.4-30.9,10.1-46.7c3.3-9.3,5-19.1,4.9-29.1C399.7,175.7,391,154.9,375.4,139.2z M371.4,218.4c-6.4,18-10.3,36-11.5,53.4
	l-1,13.8h-29c1.8-6.6,3.9-13.2,6.3-19.9c4.5-12.5,6.7-25.7,6.6-39.1c-0.2-29.6-11.9-57.6-33-78.8c-3.1-3.1-6.3-6-9.6-8.7
	c9.3,0,15.7,0,16.5,0c31.8,0.6,57.9,26.9,58.2,58.8C374.9,205,373.7,211.9,371.4,218.4z"fill={'#D876A7'}/>

	

    
    </Svg>
    
        
  );
};

export default SelfAwarenessIcon;