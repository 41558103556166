import { StyleSheet, Pressable, Image, Platform, Modal } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";
import { useNavigation } from "@react-navigation/native";
import { getLast60Days } from "../functions/last60Days";
import { Picker } from "@react-native-picker/picker";
import useColorScheme from "../hooks/useColorScheme";
import { prettyDate } from "../functions/prettyDate";
import { BoldText } from "../constants/styledText";
import ActionButton from "./ActionButton";
interface PickADateProps {
  date: string;
  setDate: (date: string) => void;
}
const PickADate: React.FC<PickADateProps> = ({ date, setDate }) => {
  const colorScheme = useColorScheme();
  
  const arrayForPicker = getLast60Days();
  const [modalVisible, setModalVisible] = useState(false);
  const [pressed, setPressed] = useState(false);
  console.log("date", date);
  console.log("selectedDate", selectedDate);

  // Find the matching date object from arrayForPicker
  const findDateInArray = (dateStr: string) => {
    return arrayForPicker.find(item => item.machineReadableDate === dateStr) || arrayForPicker[0];
  };

  // Keep internal state synchronized with prop
  const [selectedDate, setSelectedDate] = useState(findDateInArray(date));

  // Update internal state when prop changes
  useEffect(() => {
    setSelectedDate(findDateInArray(date));
  }, [date]);

  const changeDate = () => {
    setDate(selectedDate.machineReadableDate);
    setModalVisible(false);
  };


  const handlePickerChange = (itemValue: string) => {
    const newSelectedDate = arrayForPicker.find(
      item => item.machineReadableDate === itemValue
    );
    if (newSelectedDate) {
      setSelectedDate(newSelectedDate);
      if (Platform.OS === "web") {
        // On web, update parent immediately
        setDate(newSelectedDate.machineReadableDate);
      }
    }
  };


 

  return (
    
<>
      {Platform.OS === "web" ? (
        <Picker
        itemStyle={
          {
            fontSize: 16, fontFamily: "avenir-next",color: Colours[colorScheme].text,
          }
        }
          style={{
            borderWidth: 1,
            margin: 10,
            padding: 10,
            borderRadius: 8,
            fontSize: 16,
            fontFamily: "avenir-next",
            width: 280,
            // outlineStyle: "none",
            color: Colours[colorScheme].textLessEmphasised,
            backgroundColor: Colours[colorScheme].background,
            borderColor: Colours[colorScheme].edging,
          }}
          selectedValue={selectedDate.machineReadableDate}
          onValueChange={handlePickerChange}
          // onValueChange={(itemValue, itemIndex) => {
          //   const newSelectedDate = arrayForPicker.find((item) => item.machineReadableDate === itemValue);
          //   setSelectedDate(newSelectedDate);
          //   setDate(newSelectedDate.machineReadableDate);
          // }}
          >
          {arrayForPicker.map((item, index) => (
            <Picker.Item style={{}} key={index} label={item.humanReadableDate} value={item.machineReadableDate} />
          ))}
        </Picker>
      ) : (
        <>
          <Pressable
            style={{ flexDirection: "row", alignItems: "center" }}
            onPress={
              () => setModalVisible(true)
            }>
            <BoldText style={{ marginRight: 5, color: Colours.light.buttons }}>{prettyDate(date)}</BoldText>
            <Feather name="edit" size={12} color={Colours.light.buttons} />
          </Pressable>
          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}>
            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", flex: 1, width: "100%", alignItems: "center", justifyContent: "center" }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colours[colorScheme].edging,
                  backgroundColor: Colours[colorScheme].almostBackground,
                  // backgroundColor:'transparent',
                  // alignItems: "center",
                  margin: 10,
                  padding: 10,
                  borderRadius: 8,
                  // alignItems: "center",
                  // fontSize: 12,
                  // fontFamily: "avenir-next",
                  width: "90%",
                }}><Text style={{color:'red'}}>hi</Text>
                <View style={{ backgroundColor: "transparent", height: 50 }}>
                  <Pressable
                    onPress={() => {
                      setModalVisible(!modalVisible);
                      VibrateOnce();
                    }}
                    onPressOut={() => setPressed(false)}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent",
                      borderRadius: 5,
                    }}>
                    <Feather name="x" color={pressed ? Colours.palette.logoGreen : Colours[colorScheme].buttons} size={30} />
                  </Pressable>
                </View>
                <Picker
                  selectedValue={selectedDate.machineReadableDate}
                  onValueChange={handlePickerChange}
                  // onValueChange={(itemValue, itemIndex) => setSelectedDate(arrayForPicker.find((item) => item.machineReadableDate === itemValue))}
                  itemStyle={
                    {
                      // fontSize: 16, fontFamily: "avenir-next',
                    }
                  }
                  style={
                    {
                      // color:'red',
                      
                      // backgroundColor:'green'
                      // borderWidth: 1,
                      // margin: 10,
                      // padding: 10,
                      // borderRadius: 8,
                      // fontSize: 12,
                      // fontFamily: "avenir-next",
                      // width: '80%',
                      // outlineStyle: "none",
                      // color: Colours[colorScheme].textLessEmphasised,
                      // backgroundColor: Colours[colorScheme].background,
                      // borderColor: Colours[colorScheme].edging,
                    }
                  }>
                  {arrayForPicker.map((item, index) => (
                    <Picker.Item style={{}} key={index} label={item.humanReadableDate} value={item.machineReadableDate} />
                  ))}
                </Picker>
                
                <View style={{ alignItems: "center", width: "100%" }}>
                  <ActionButton icon={"check"} text={"Confirm"} onPressFunction={changeDate} />
                </View>
              </View>
            </View>
          </Modal>
        </>
      )}
  </>
  );
};

export default PickADate;
