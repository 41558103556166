import React from "react";
import Svg, { Path } from "react-native-svg";

const CollaborationIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1"  viewBox="0 0 500 500" >
      <Path  d="M388.5,159l-52.3,0l-43.3-23.9c-18-9.9-39.9-10.6-58.5-1.7l-52.8,25.2l-70.8,0.5l-23,143.6l50.8,0
	c1.2,10.5,7.1,20,16.2,25.9l52.6,33.6c5.1,3.5,11,5.2,16.8,5.2c5.7,0,11.3-1.6,16.1-4.7c4.3,1.7,8.9,2.8,13.6,3.2
	c3.8,0.3,7,0.5,9.7,0.5c1.3,0,2.4,0,3.4-0.1c11.7-0.7,22.4-4.9,30.9-12.1c0.4-0.4,1-0.8,2.1-1.3c2-0.9,2.6-0.9,4.3-1.1
	c3.3-0.3,6.8-0.8,12-3c5.2-2.3,7.9-4.4,10.4-6.7c1.3-1.1,1.8-1.6,3.8-2.4c3.8-1.7,7-2.2,10.4-2.8c1.9-0.3,3.9-0.7,5.9-1.3
	c8.9-2.5,15.9-8.4,19.8-16.8c2.3-4.9,3.5-10.4,3.5-16l41.4,0L388.5,159z M228,340.3c-1.5,2.1-4.4,2.6-6.6,1.2l-53.3-34
	c-3.5-2.3-5.4-6.4-4.8-10.6l1-6.9l62.5,43.7C229,335.3,229.5,338.2,228,340.3z M344,308c-0.9,1.8-2.1,2.8-3.9,3.4
	c-0.9,0.2-2.2,0.5-3.6,0.7c-4,0.7-9.5,1.7-15.9,4.5c-5.2,2.3-7.9,4.4-10.4,6.7c-1.3,1.1-1.8,1.6-3.8,2.4c-2,0.9-2.6,0.9-4.3,1.1
	c-3.3,0.3-6.8,0.8-12,3c-3.1,1.4-5.8,3-8.3,5.1c-4.3,3.6-10.1,5.9-16.3,6.3c-1.1,0.1-3.9,0.1-9.6-0.3c-0.7-0.1-1.4-0.2-2.1-0.3
	c1-10.3-3.4-20.9-12.5-27.3l-66.8-46.8c-6.2-4.3-14.1-5.1-21-2.2c-5.9,2.5-10.2,7.4-12.1,13.3l-24.2,0l15-93.8l14.8-0.1
	c-0.6,2.2-0.9,4.5-0.8,6.8c0.3,8.1,5.1,15.5,12.3,19.2c12.6,6.5,27.2,8.2,41,4.9l39.3-9.5c8.5-2,17.7,0.6,23.8,6.9l79.2,80.1
	c0.8,0.9,1.4,1.9,1.8,3C345.4,299.5,345.6,304.6,344,308z M362,277.7c-0.4-0.5-0.8-1-1.2-1.5c-0.4-0.5-0.8-1-1.3-1.5l-24.8-25.1
	c11,0,18.4-2.2,19.1-2.5l-7.5-23.8l0.1,0c-1.2,0.3-29.3,8.3-55.4-17.9l-10.7-10.9c-9.5-9.6-22.6-14.9-36-14.9
	c-3.8,0-7.6,0.4-11.4,1.3l-39.4,9.5c-6.3,1.5-12.9,1.2-18.9-0.8l12.7-6l0.4,0l45.6-22l11.8-5.6c11.3-5.4,24.6-5,35.6,1l49,27l37.4,0
	l15,93.7L362,277.7z" fill={'#0DBFBF'}/>

	

    
    </Svg>
    
        
  );
};

export default CollaborationIcon;