import React, { useContext, useEffect, useState } from "react";
import { Pressable, ScrollView, StyleSheet } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import { Image } from "expo-image";
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { Storage } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import ActionButton from "../components/ActionButton";

const ActivitiesRoot = () => {
  const { additionalGraphicURL, additionalFileURL } = useContext(OrganisationContext);
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [downloadedImage, setDownloadedImage] = useState(null);

  function handleBookletPress() {
    VibrateOnce();
    WebBrowser.openBrowserAsync(additionalFileURL);
  }

  const fetchPhoto = async () => {
    const file = await Storage.get(additionalGraphicURL, { level: "public" });
    setDownloadedImage(file);
  };

  useEffect(() => {
    if (additionalGraphicURL) {
      fetchPhoto();
    } else {
      console.log("no photo");
    }
  }, []);

  const NavigateToActivitiesSelection = () => {
    navigation.navigate("ActivitiesSelection");
  };

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, 
    flex: 1,
     width:'100%' }}>
    
      <View style={{ flex: 1, borderRadius: 10, backgroundColor: Colours[colorScheme].almostBackground,
        //  alignItems: "center", 
        //  justifyContent: "center"
          }}>
        
      <ScrollView
      
    contentContainerStyle={{flexGrow:1}}
    style={{ backgroundColor: 'transparent'}}>

      <View style={{width:'90%',paddingVertical:20, alignSelf:'center', backgroundColor:'transparent', alignItems:'center'}}>
        
        <Pressable onPress={handleBookletPress}>
          {downloadedImage ? (
            <Image source={{ uri: downloadedImage }} contentFit="contain" style={{ height: 400, width: 400 }} />
          ) : (
            <View style={{height:400, backgroundColor:'transparent'}}>
              <FlexibleLearningIcon color={Colours[colorScheme].text} />
              <Text>Click here for the Flexible Learning booklet.</Text>
            </View>
          )}
        </Pressable>

        <ActionButton icon={"book-open"} text={"View the booklet"} onPressFunction={handleBookletPress} />

        <ActionButton icon={"check-square"} text={"Take or amend a register"} onPressFunction={NavigateToActivitiesSelection} />



   
</View>
        
</ScrollView>
       
      </View>

 </View>
  );
};

export default ActivitiesRoot;

const styles = StyleSheet.create({});
