import React, { useEffect, useState } from "react";
import { Pressable, Image, Platform, Alert } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import { Picker } from "@react-native-picker/picker";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import RenderTextInput from "./RenderTextInput";
import ActionButton from "./ActionButton";
import TimelineIcon from "../../assets/icons/Timeline";
import Colours from "../constants/colours";
import useColorScheme from "../hooks/useColorScheme";
import { VibrateOnce } from "../functions/vibration";
import { API, graphqlOperation } from "aws-amplify";
import ActivityIndicatorAnimation from "./ActivityIndicator";

export const updateEnrolment = /* GraphQL */ `
  mutation UpdateEnrolment(
    $input: UpdateEnrolmentInput!
    $condition: ModelEnrolmentConditionInput
  ) {
    updateEnrolment(input: $input, condition: $condition) {
      id
      internalUniqueID
      contextData1
      contextData2
      contextData3
      groupingData1
      groupingData2
      groupingData3
      photoPermission
      scoringData1
      scoringData2
      scoringData3
      startDate
      endDate
      isActive
      userID
      organisationID
      
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
interface EnrolmentDetailsFormProps {
  enrolmentDataFromServer: object;
  isLoading: boolean;
  userID:string;
}

const EnrolmentDetailsForm: React.FC<EnrolmentDetailsFormProps> = ({ enrolmentDataFromServer, isLoading, userID }) => {
  const colorScheme = useColorScheme();
  const [originalUserData, setOriginalUserData] = useState({});
  const [isAccountSectionVisible, setIsAccountSectionVisible] = React.useState(true);
  const [enrolmentDataInState, setEnrolmentDataInState] = useState(enrolmentDataFromServer);
  const [formData, setFormData] = useState({}); // State to track form data
  const [isFormDirty, setisFormDirty] = useState(false); // State to track if there are unsaved changes
  const [isEnrolmentSectionVisible, setIsEnrolmentSectionVisible] = useState(true);



  useEffect(() => {
    setEnrolmentDataInState(enrolmentDataFromServer);
    setOriginalUserData(enrolmentDataFromServer); // Store the original data
  }, [enrolmentDataFromServer]);





  const handleCancel = () => {
    setEnrolmentDataInState(originalUserData);
    setFormData({});
    setisFormDirty(false);
  };


  // Function to handle save action
  const handleSave = async () => {
    try {
      // Construct mutation input object with user ID and modified fields
      const input = {
        id: userID,
        _version: enrolmentDataInState._version,
        ...formData,
      };


console.log('input', input)


      // Send mutation to update user data
      const response = await API.graphql(graphqlOperation(updateEnrolment, { input }));
 
console.log('response',response)

      // Reset form data and set isDirty to false after successful update
      setFormData({});

      // Generate the alert message
      let alertMessage = `User's data has been updated 👌`;

      if (Platform.OS === "web") {
        alert(alertMessage);
      } else {
        Alert.alert(alertMessage);
      }
      setisFormDirty(false);
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle error, e.g., display an error message to the user
    }
  };

  // Function to handle input change and track changes
  const handleInputChange = (fieldName, newValue) => {

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
    setEnrolmentDataInState((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));

    setisFormDirty(true); // Set isDirty to true when there are unsaved changes


    console.log('formData', formData)
  };



  return (  

<>
<Pressable
          onPress={() => {
            VibrateOnce();
            setIsEnrolmentSectionVisible(!isEnrolmentSectionVisible);
          }}
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 10,
            backgroundColor: isFormDirty?Colours.palette.softRed: Colours[colorScheme].almostBackground,
          }}>
          <Feather name={isAccountSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

          <BoldText style={{ color: Colours[colorScheme].text }}>{isFormDirty ? "Enrolment (unsaved changes)" : "Enrolment"}</BoldText>
        </Pressable>

        {isEnrolmentSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              flexDirection: "row",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}>
            {!isLoading ? (
              <>
                {isEnrolmentSectionVisible && (
                  <>
                    <View style={{ width: "50%", backgroundColor: "transparent" }}>
                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>House:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            // selectedValue={userData.Enrolments.items[0].groupingData1 ? userData.Enrolments.items[0].groupingData1 : "none selected"}
                            selectedValue={enrolmentDataInState.groupingData1 ? enrolmentDataInState.groupingData1 : "Unknown"}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            onValueChange={(value) => handleInputChange("groupingData1", value)}
                          >
                            <Picker.Item label="Bremner" value={"Bremner"} />
                            <Picker.Item label="Robinson" value={"Robinson"} />
                            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
                            <Picker.Item label="Trueman" value={"Trueman"} />
                            <Picker.Item label="Unknown" value={"Unknown"} />
                          </Picker>
                        </View>
                      </View>

                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>Year group:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            // selectedValue={userData.Enrolments.items[0].groupingData3 ? userData.Enrolments.items[0].groupingData3 : "none selected"}
                            selectedValue={enrolmentDataInState.groupingData3 ? enrolmentDataInState.groupingData3 : "Unknown"}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            onValueChange={(value) => handleInputChange("groupingData3", value)}
                          >
                            <Picker.Item label="Reception" value={"Reception"} />
                            <Picker.Item label="Year 1" value={"Year 1"} />
                            <Picker.Item label="Year 2" value={"Year 2"} />
                            <Picker.Item label="Year 3" value={"Year 3"} />
                            <Picker.Item label="Year 4" value={"Year 4"} />
                            <Picker.Item label="Year 5" value={"Year 5"} />
                            <Picker.Item label="Year 6" value={"Year 6"} />
                            <Picker.Item label="Year 7" value={"Year 7"} />
                            <Picker.Item label="Year 8" value={"Year 8"} />
                            <Picker.Item label="Year 9" value={"Year 9"} />
                            <Picker.Item label="Year 10" value={"Year 10"} />
                            <Picker.Item label="Year 11" value={"Year 11"} />
                            <Picker.Item label="Class of 2023" value={"Class of 2023"} />
                            <Picker.Item label="Class of 2024" value={"Class of 2024"} />
                            <Picker.Item label="Alumni" value={"Alumni"} />
                            <Picker.Item label="Unknown" value={"Unknown"} />
                          </Picker>
                        </View>
                      </View>

                      <RenderTextInput label="Coaching group" value={enrolmentDataInState.groupingData2?enrolmentDataInState.groupingData2:'Unknown'} onChange={(newValue) => handleInputChange("groupingData2", newValue)} />
                    </View>
                    <View style={{ 
                        // flexDirection: 'row',
                         marginHorizontal: 20,
                        backgroundColor: "transparent", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        {isFormDirty && 
                        <>
                        <ActionButton icon="x" text="Cancel" onPressFunction={handleCancel} />
                        <ActionButton icon="save" text="Save" onPressFunction={handleSave} /></>}
                      </View>
                  </>
                )}
              </>
            ) : (
              <ActivityIndicatorAnimation />
            )}
          </View>
        )}


{!isEnrolmentSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              // height: "60%",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}></View>
        )}
</>
  );
};

export default EnrolmentDetailsForm;
