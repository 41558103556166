import { StyleSheet, Pressable, Modal, Platform } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useState } from "react";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";
import useColorScheme from "../hooks/useColorScheme";
import { BoldText } from "../constants/styledText";
import ActionButton from "./ActionButton";
import { Picker } from "@react-native-picker/picker";

interface RenderPickerProps<T> {
    selectedItem: T | null;
    setSelectedItem: (item: T | null) => void;
    items: T[];
    itemLabel: (item: T) => string;
  }
  
  const RenderPicker = <T,>({
    selectedItem,
    setSelectedItem,
    items,
    itemLabel
  }: RenderPickerProps<T>) => {
    const colorScheme = useColorScheme();
    const [modalVisible, setModalVisible] = useState(false);
    const [pressed, setPressed] = useState(false);
  
    const handlePickerChange = (itemValue: string) => {
      const newSelectedItem = items.find(item => itemLabel(item) === itemValue) || null;
      setSelectedItem(newSelectedItem);
      if (Platform.OS === "web") {
        // On web, update parent immediately
        setSelectedItem(newSelectedItem);
      }
    };
  
    const changeSelectedItem = () => {
      setSelectedItem(selectedItem);
      setModalVisible(false);
    };
  
    return (
      <>
        {Platform.OS === "web" ? (
          <Picker
            itemStyle={{
              fontSize: 16,
              fontFamily: "avenir-next"
            }}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 16,
              fontFamily: "avenir-next",
              width: 280,
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].background,
              borderColor: Colours[colorScheme].edging
            }}
            selectedValue={selectedItem ? itemLabel(selectedItem) : undefined}
            onValueChange={handlePickerChange}
          >
            <Picker.Item label="Select an item" value={undefined} />
            {items.map((item, index) => (
              <Picker.Item
                style={{color: Colours[colorScheme].text}}
                key={index}
                label={itemLabel(item)}
                value={itemLabel(item)}
              />
            ))}
          </Picker>
        ) : (
          <>
            <Pressable
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={() => setModalVisible(true)}
            >
              <BoldText style={{ marginRight: 5, color: Colours.light.buttons }}>
                {selectedItem ? itemLabel(selectedItem) : "Select an item"}
              </BoldText>
              <Feather name="edit" size={12} color={Colours.light.buttons} />
            </Pressable>
            <Modal
              animationType="fade"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  flex: 1,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: Colours[colorScheme].edging,
                    backgroundColor: Colours[colorScheme].background,
                    margin: 10,
                    padding: 10,
                    borderRadius: 8,
                    width: "90%"
                  }}
                >
                  <View style={{ backgroundColor: "transparent", height: 50 }}>
                    <Pressable
                      onPress={() => {
                        setModalVisible(!modalVisible);
                        VibrateOnce();
                      }}
                      onPressOut={() => setPressed(false)}
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        backgroundColor: pressed
                          ? Colours[colorScheme].almostBackground
                          : "transparent",
                        borderRadius: 5
                      }}
                    >
                      <Feather
                        name="x"
                        color={
                          pressed
                            ? Colours.palette.logoGreen
                            : Colours[colorScheme].buttons
                        }
                        size={30}
                      />
                    </Pressable>
                  </View>
                  <Picker
                    selectedValue={selectedItem ? itemLabel(selectedItem) : undefined}
                    onValueChange={handlePickerChange}
                    itemStyle={{
                      fontSize: 16,
                      fontFamily: "avenir-next",
                      color:Colours[colorScheme].text
                    }}
                    style={{color:Colours[colorScheme].text}}
                  >
                    <Picker.Item label="Select an item" value={undefined} />
                    {items.map((item, index) => (
                      <Picker.Item
                        style={{color:Colours[colorScheme].text}}
                        key={index}
                        label={itemLabel(item)}
                        value={itemLabel(item)}
                      />
                    ))}
                  </Picker>
                  <View style={{ alignItems: "center", width: "100%" }}>
                    <ActionButton
                      icon={"check"}
                      text={"Confirm"}
                      onPressFunction={changeSelectedItem}
                    />
                  </View>
                </View>
              </View>
            </Modal>
          </>
        )}
      </>
    );
  };
  
  export default RenderPicker;
  