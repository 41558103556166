import { createContext} from "react";
const UserContext = createContext('unknown')
const AllUsersContext = createContext([])
// const AllUsersContext = createContext(

//     {allUsersContext:[],updateUsersContext: () => {},}


// )
const OrganisationContext = createContext('unknown')
const ClubAttendanceContext = createContext({allClubAttendanceForThisClub:[],updateClubAttendanceContext: () => {},})
export {UserContext, AllUsersContext, OrganisationContext, ClubAttendanceContext};