import React from "react";
import Svg, { Path } from "react-native-svg";

const CommitmentIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M278.3,332.6L257,351.4l6.2,27.8c0.6,2.6-2.2,4.6-4.4,3.2L234.2,368l-24.5,14.5c-2.3,1.3-5-0.7-4.4-3.2
	l6.2-27.8l-21.3-18.8c-2-1.7-0.9-5,1.7-5.2l28.3-2.7l11.3-26.1c1-2.4,4.5-2.4,5.5,0l11.3,26.1l28.3,2.7
	C279.2,327.6,280.3,330.8,278.3,332.6z M367.3,256.1l-5.9,13.2c-2,4.5-3.1,9.4-3.1,14.4v48.5c0,20.4-6.8,40.5-19.1,56.7l-1.2,1.6
	l-19.9-15.1l1.2-1.6c9-11.9,14-26.7,14-41.6v-48.5c0-8.5,1.8-16.8,5.2-24.6l5-11.2c-17.7,6.6-26.3,19-26.3,37.9v2.8v0.4l0,0v2.1
	c0,7.6-3.4,14.7-9.2,19.6l-11.2,9.3l-16-19.2l11.2-9.3c0.1-0.1,0.2-0.2,0.2-0.4v-2.4l-0.3-116.6c0-4.3-3.5-7.8-7.8-7.8
	s-7.8,3.5-7.8,7.8V187v61c3.3,0.9,6.7,1.9,10,3l-7.8,23.8c-55-18-108.2-0.2-108.8-0.1l-8.1-23.7c0.5-0.2,3.5-1.2,8.5-2.5v-52.6
	c0-4.3-3.5-7.7-7.8-7.7c-2.1,0-4,0.8-5.5,2.3s-2.3,3.4-2.3,5.5v141c0,13.9,3.7,27.5,10.7,39.5L144,389.2
	c-9.3-15.8-14.1-33.8-14.1-52.1V195.9c0-8.8,3.4-17,9.6-23.2c6.2-6.2,14.4-9.6,23.2-9.6c2.7,0,5.3,0.3,7.8,0.9v-6.3
	c0-18.1,14.7-32.8,32.8-32.8c3.9,0,7.7,0.7,11.1,2c5.3-10.8,16.5-18.3,29.4-18.3c17.6,0,32.1,14,32.7,31.5c2.5-0.6,5.1-0.9,7.8-0.9
	c18.1,0,32.8,14.7,32.8,32.8l0.2,61.8c5.8-4.3,12.6-7.7,20.3-10.3c9.2-3.1,19-0.6,25.6,6.7C369.7,237.2,371.2,247.2,367.3,256.1z
	 M195.4,157.8v37.9c0,0.1,0,0.1,0,0.2v47.6c4.8-0.6,10-1.2,15.6-1.6v-64v-20.2c0-4.3-3.5-7.8-7.8-7.8
	C198.9,150,195.4,153.5,195.4,157.8z M251.5,186.8v-14.9v-30.5c0-4.3-3.5-7.8-7.8-7.8s-7.8,3.5-7.8,7.8v16.4V178v63.8
	c5.1,0.2,10.3,0.7,15.6,1.3V186.8z" fill={'#9CE4E0'}/>

	

    
    </Svg>
    
        
  );
};

export default CommitmentIcon;