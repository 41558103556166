import React from "react";
import Svg, { Path } from "react-native-svg";

const CompassionIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M397,188.9c-5.3-27.8-20.9-50.9-43.8-65c-31-19-68.3-17.4-103.5,4.1c-35.2-21.5-72.5-23.1-103.5-4.1
	c-23,14.1-38.5,37.2-43.8,65c-7.1,37,4.1,78.5,30.5,113.8c38.8,51.8,109.5,78.6,112.5,79.7l4.4,1.6l4.4-1.6
	c3-1.1,73.6-27.9,112.5-79.7C392.9,267.4,404,225.9,397,188.9z M250.3,298.2l-13,0l-0.1,53.3c-22.2-10.6-60.8-32.3-84.4-63.8
	c-22.3-29.7-31.7-64-26-94.2c4-21.1,15.2-37.9,32.4-48.4c11.3-7,22.7-9.6,33.4-9.6c17.5,0,33.3,7.1,44.5,14v21.7
	c-16.3,3.9-28.4,18.6-28.4,36.1c0,9.9,3.9,19.2,10.9,26.2c4.9,4.9,10.9,8.3,17.5,9.9l0.1,30.6h15c2.6,0.4,5.1,1.6,7,3.5
	c2.5,2.5,3.7,5.7,3.6,9.2C262.5,293.2,256.8,298.2,250.3,298.2z M346.4,287.7c-23.6,31.5-62.3,53.3-84.4,63.8v-30.2
	c14.8-4.9,25.6-18.9,25.6-35.3c0-9.9-3.9-19.2-10.9-26.2c-4.2-4.2-9.2-7.3-14.7-9.1l0.1-31.3h-16.6c-3.1-0.1-6.1-1.3-8.4-3.6
	c-2.7-2.7-3.9-6.3-3.5-10c0.7-6.1,6-10.6,12.1-10.7h16.1l0.1-45.7c17.9-11.1,47.9-22.9,78-4.5c17.1,10.5,28.3,27.2,32.4,48.4
	C378.2,223.7,368.7,258,346.4,287.7z" fill={'#FD6D55'}/>

	

    
    </Svg>
    
        
  );
};

export default CompassionIcon;