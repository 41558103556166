import React from "react";
import Svg, { Path } from "react-native-svg";

const InclusivenessIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M249.6,93.7l-133,76.8V324l133,76.8l133-76.8V170.5L249.6,93.7z M357.6,295.2L320.9,274v-67.9l-58.8-33.9v-42.4
	l95.5,55.1V295.2z M249.6,193.8l33.9,19.6l-33.7,19.8l-34.2-19.8L249.6,193.8z M203.3,235.3l34,19.6v38.7l-34-19.6V235.3z
	 M262.4,254.8l33.5-19.7V274l-33.5,19.3V254.8z M237.1,129.8v42.4l-58.8,33.9V274l-36.7,21.2V184.9L237.1,129.8z M154.1,316.8
	l36.7-21.2l58.7,33.9l58.7-33.9l36.7,21.2L249.6,372L154.1,316.8z" fill={'#7893DA'}/>

	

    
    </Svg>
    
        
  );
};

export default InclusivenessIcon;