import React from "react";
import Svg, { Path } from "react-native-svg";

const IntegrityIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M249.6,103.5l-130.3,21.4v151.2c0,29.4,16.2,56.1,42.4,69.6l87.9,45.4l87.9-45.4c26.1-13.5,42.4-40.1,42.4-69.6
	V124.9L249.6,103.5z M354.9,276.1c0,20-11,38.2-28.8,47.3l-76.4,39.5l-76.4-39.5c-17.8-9.2-28.8-27.3-28.8-47.3V146.2l105.3-17.3
	l105.3,17.3v129.9H354.9z M165.8,237.1c0,46.2,37.6,83.8,83.8,83.8s83.8-37.6,83.8-83.8s-37.6-83.8-83.8-83.8
	S165.8,190.9,165.8,237.1z M308.4,237.1c0,32.4-26.4,58.8-58.8,58.8s-58.8-26.4-58.8-58.8s26.4-58.8,58.8-58.8
	S308.4,204.7,308.4,237.1z M240.2,242.7l37.5-37.5l17.7,17.7l-55.2,55.2l-36-36l17.7-17.7L240.2,242.7z"fill={'#CAC5B1'}/>

	

    
    </Svg>
    
        
  );
};

export default IntegrityIcon;