import React from "react";
import Svg, { Path } from "react-native-svg";

const CommunicationIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M158.5,246.1c0-10.9,8.9-19.8,19.8-19.8c10.9,0,19.8,8.9,19.8,19.8c0,10.9-8.9,19.8-19.8,19.8
	C167.3,265.9,158.5,257.1,158.5,246.1z M230.6,265.9c10.9,0,19.8-8.9,19.8-19.8c0-10.9-8.9-19.8-19.8-19.8
	c-10.9,0-19.8,8.9-19.8,19.8C210.8,257.1,219.7,265.9,230.6,265.9z M282.9,265.9c10.9,0,19.8-8.9,19.8-19.8
	c0-10.9-8.9-19.8-19.8-19.8s-19.8,8.9-19.8,19.8C263.1,257.1,272,265.9,282.9,265.9z M397.9,137v117c0,11.5-9.4,20.9-20.9,20.9
	h-17.2v35.3c0,13.4-10.9,24.2-24.2,24.2l-158-0.1l-76.2,44.1V179.8c0-13.4,10.9-24.2,24.2-24.2h81.8V137c0-11.5,9.4-20.9,20.9-20.9
	H377C388.5,116.2,397.9,125.5,397.9,137z M334.8,180.5H126.4V335l44.5-25.7l163.9,0.1V180.5z M372.9,141.2H232.5v14.4h103.1
	c13.4,0,24.2,10.9,24.2,24.2v70.1h13.1L372.9,141.2L372.9,141.2z" fill={'#85E09B'}/>

	

    
    </Svg>
    
        
  );
};

export default CommunicationIcon;