import React, { useEffect, useState } from "react";
import { Pressable, Image, Platform, Alert } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import { Picker } from "@react-native-picker/picker";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import RenderTextInput from "./RenderTextInput";
import ActionButton from "./ActionButton";
import TimelineIcon from "../../assets/icons/Timeline";
import Colours from "../constants/colours";
import useColorScheme from "../hooks/useColorScheme";
import { VibrateOnce } from "../functions/vibration";
import { API, graphqlOperation } from "aws-amplify";

import ActivityIndicatorAnimation from "./ActivityIndicator";
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      pushToken
      Enrolments {
        nextToken
        startedAt
        __typename
      }
      Opportunities {
        nextToken
        startedAt
        __typename
      }
      AwardedBadges {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
        __typename
      }
      MomentsContributed {
        nextToken
        startedAt
        __typename
      }
      MomentsActuallUploaded {
        nextToken
        startedAt
        __typename
      }
      Tags {
        nextToken
        startedAt
        __typename
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      AttendanceMarksRecordedBy {
        nextToken
        startedAt
        __typename
      }
      DailyPointsTotals {
        nextToken
        startedAt
        __typename
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
      }
      DeliveredTrainings {
        nextToken
        startedAt
        __typename
      }
      TrainingAttendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
interface AccountDetailsFormProps {
  userDataFromServer: any;
  downloadedImage: string;
  // houseColor: string;
  isLoading: boolean;
  userID:string;
}

const AccountDetailsForm: React.FC<AccountDetailsFormProps> = ({ userDataFromServer, downloadedImage, isLoading, userID }) => {
  const colorScheme = useColorScheme();
  const [originalUserData, setOriginalUserData] = useState({});
  const [isAccountSectionVisible, setIsAccountSectionVisible] = React.useState(true);
  const [userData, setUserData] = useState(userDataFromServer);
  const [formData, setFormData] = useState({}); // State to track form data
  const [isUserDirty, setIsUserDirty] = useState(false); // State to track if there are unsaved changes
  const house = userData ? userData.Enrolments?.items[0]?.groupingData1 : null;
  // const coach = userData ? userData.Enrolments?.items[0]?.groupingData2 : null;
  // const year = userData ? userData.Enrolments?.items[0]?.groupingData3 : null;
  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";


  useEffect(() => {
    setUserData(userDataFromServer);
    setOriginalUserData(userDataFromServer); // Store the original data
    console.log('userID', userID);
  }, [userDataFromServer]);





  const handleCancel = () => {
    setUserData(originalUserData);
    setFormData({});
    setIsUserDirty(false);
  };


  // Function to handle save action
  const handleSave = async () => {
    try {
      // Construct mutation input object with user ID and modified fields
      const input = {
        id: userID,
        _version: userData._version, //IMPORTANT. AWS NEEDS THIS TO UPDATE!
        ...formData,
      };

      // Send mutation to update user data
      const response = await API.graphql(graphqlOperation(updateUser, { input }));
      //   console.log("User data updated:", response);
      //   console.log("input", input);
      // Reset form data and set isDirty to false after successful update
      setFormData({});

      // Generate the alert message
      let alertMessage = `User's data has been updated 👌`;

      if (Platform.OS === "web") {
        alert(alertMessage);
      } else {
        Alert.alert(alertMessage);
      }
      setIsUserDirty(false);
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle error, e.g., display an error message to the user
    }
  };

  // Function to handle input change and track changes
  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));

    setIsUserDirty(true); // Set isDirty to true when there are unsaved changes
  };

  const renderDateOfBirthPicker = () => {
    const birthDate = userData.dateOfBirth ? new Date(userData.dateOfBirth) : new Date();
    const years = [];
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100; // Set a minimum year, e.g., 100 years ago
    for (let year = currentYear; year >= minYear; year--) {
      years.push(year.toString());
    }

    const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
    const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
    const days = Array.from({ length: daysInMonth(birthDate.getFullYear(), birthDate.getMonth() + 1) }, (_, i) => (i + 1).toString().padStart(2, "0"));

    return (
      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
          <Text>Date of birth:</Text>
        </View>
        <View style={{ backgroundColor: "transparent", width: "50%", flexDirection: "row", alignItems: "center" }}>
          <Picker
            selectedValue={birthDate?birthDate.getDate().toString().padStart(2, "0"):''}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("day", value)}>
            {days.map((day) => (
              <Picker.Item key={day} label={day} value={day} />
            ))}
          </Picker>

          <Picker
            selectedValue={birthDate?(birthDate.getMonth() + 1).toString().padStart(2, "0"):''}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("month", value)}>
            {months.map((month) => (
              <Picker.Item key={month} label={month} value={month} />
            ))}
          </Picker>
          <Picker
            selectedValue={birthDate?birthDate.getFullYear().toString():''}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("year", value)}>
            {years.map((year) => (
              <Picker.Item key={year} label={year} value={year} />
            ))}
          </Picker>
        </View>
      </View>
    );
  };

  return (  

<>
<Pressable
          onPress={() => {
            VibrateOnce();
            setIsAccountSectionVisible(!isAccountSectionVisible);
          }}
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 10,
            backgroundColor: isUserDirty?Colours.palette.softRed: Colours[colorScheme].almostBackground,
          }}>
          <Feather name={isAccountSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

          <BoldText style={{ color: Colours[colorScheme].text }}>{isUserDirty ? "Account (unsaved changes)" : "Account"}</BoldText>
        </Pressable>

        {isAccountSectionVisible && (
          <>
            <View
              style={{
                marginBottom: 10,
                padding: 10,
                borderBottomLeftRadius: 10,
                // flexDirection: "row",
                borderBottomRightRadius: 10,
                width: "100%",
                backgroundColor: Colours[colorScheme].background,
              }}>
              {!isLoading ? (
                <>
                  {userData && (
                    <>
                      <View style={{ flexDirection: "row", width: "100%" }}>
                        <View style={{ width: "50%", backgroundColor: "transparent" }}>
                          <RenderTextInput label="Recognition ID" value={userData.id?userData.id:''} onChange={(newValue) => handleInputChange("id", newValue)} />
                          <RenderTextInput label="Legal surname" value={userData.surname?userData.surname:''} onChange={(newValue) => handleInputChange("surname", newValue)} />
                          <RenderTextInput label="Legal first name" value={userData.firstName?userData.firstName:''} onChange={(newValue) => handleInputChange("firstName", newValue)} />
                          <RenderTextInput label="Preferred surname" value={userData.preferredSurname?userData.preferredSurname:''} onChange={(newValue) => handleInputChange("preferredSurname", newValue)} />
                          <RenderTextInput label="Preferred first name" value={userData.preferredFirstName?userData.preferredFirstName:''} onChange={(newValue) => handleInputChange("preferredFirstName", newValue)} />

                          <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                            <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                              <Text>Gender:</Text>
                            </View>
                            <View style={{ backgroundColor: "transparent", width: "50%" }}>
                              <Picker
                                selectedValue={userData.gender?userData.gender:''}
                                style={{
                                  borderWidth: 1,
                                  margin: 10,
                                  padding: 10,
                                  borderRadius: 8,
                                  fontSize: 12,
                                  fontFamily: "avenir-next",
                                  color: Colours[colorScheme].textLessEmphasised,
                                  backgroundColor: Colours[colorScheme].almostBackground,
                                  borderColor: Colours.palette.logoGreen,
                                }}
                                onValueChange={(value) => handleInputChange("gender", value)}>
                                <Picker.Item label="Male" value="M" />
                                <Picker.Item label="Female" value="F" />
                              </Picker>
                            </View>
                          </View>
                          {renderDateOfBirthPicker()}
                          <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                            <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                              <Text>Status:</Text>
                            </View>
                            <View style={{ backgroundColor: "transparent", width: "50%" }}>
                              <Picker
                                selectedValue={userData.isActive?userData.isActive:''}
                                style={{
                                  borderWidth: 1,
                                  margin: 10,
                                  padding: 10,
                                  borderRadius: 8,
                                  fontSize: 12,
                                  fontFamily: "avenir-next",
                                  color: Colours[colorScheme].textLessEmphasised,
                                  backgroundColor: Colours[colorScheme].almostBackground,
                                  borderColor: Colours.palette.logoGreen,
                                }}
                                onValueChange={(value) => handleInputChange("isActive", value)}>
                                <Picker.Item label="Active" value={true} />
                                <Picker.Item label="Inactive" value={false} />
                              </Picker>
                            </View>
                          </View>
                          <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                            <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                              <Text>Type:</Text>
                            </View>
                            <View style={{ backgroundColor: "transparent", width: "50%" }}>
                              <Picker
                                selectedValue={userData.type?userData.type:''}
                                style={{
                                  borderWidth: 1,
                                  margin: 10,
                                  padding: 10,
                                  borderRadius: 8,
                                  fontSize: 12,
                                  fontFamily: "avenir-next",
                                  color: Colours[colorScheme].textLessEmphasised,
                                  backgroundColor: Colours[colorScheme].almostBackground,
                                  borderColor: Colours.palette.logoGreen,
                                }}
                                onValueChange={(value) => handleInputChange("type", value)}>
                                <Picker.Item label="Child" value="CHILD" />
                                <Picker.Item label="Staff" value="STAFF" />
                                <Picker.Item label="Admin" value="ADMIN" />
                              </Picker>
                            </View>
                          </View>
                          <RenderTextInput label="Email address" value={userData.email?userData.email:''} onChange={(newValue) => handleInputChange("email", newValue)} />
                        </View>

                        <View style={{ width: "50%", backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
                          {/* photo */}

                          <View
                            style={{
                              backgroundColor: "transparent",
                              width: 290,
                              marginHorizontal: 0,
                            }}>
                            {/* fall back icon that sits behind the photo */}

                            <View
                              style={{
                                height: 290,
                                width: 290,

                                borderRadius: 220,
                                borderColor: houseColor,
                                borderWidth: 5,
                              }}>
                              <TimelineIcon color={"#ccc"} />

                              <Image
                                source={{ uri: downloadedImage }}
                                style={{
                                  height: 280,
                                  width: 280,

                                  borderRadius: 220,

                                  position: "absolute",
                                }}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={{ 
                        // flexDirection: 'row',
                         marginHorizontal: 20,
                        backgroundColor: "transparent", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        {isUserDirty && 
                        <>
                        <ActionButton icon="x" text="Cancel" onPressFunction={handleCancel} />
                        <ActionButton icon="save" text="Save" onPressFunction={handleSave} /></>}
                      </View>
                    </>
                  )}
                </>
              ) : (
                <ActivityIndicatorAnimation />
              )}
            </View>
          </>
        )}

    {!isAccountSectionVisible && (
      <View
        style={{
          marginBottom: 10,
          padding: 10,
          borderBottomLeftRadius: 10,
          // height: "60%",
          borderBottomRightRadius: 10,
          width: "100%",
          backgroundColor: Colours[colorScheme].background,
        }}></View>
    )}
</>
  );
};

export default AccountDetailsForm;
