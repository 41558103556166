import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, FlatList, TextInput, Platform, Keyboard, useWindowDimensions, ScrollView, Alert } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { OrganisationContext, UserContext, ClubAttendanceContext, AllUsersContext } from "../hooks/Contexts";
import { Image } from "expo-image";
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { API, Storage, graphqlOperation } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import { useNavigation } from "@react-navigation/native";
import SearchBarActivity from "../components/SearchBarActivity";
import ListItemOpportunity from "../components/ListItemOpportunity";
import { prettyDate } from "../functions/prettyDate";
import CloseButton from "../components/CloseButton";
import { BoldText } from "../constants/styledText";
import SearchBar from "../components/SearchBar";
import PhotoListItemRegister from "../components/PhotoListItemRegister";
import PhotoListItemRegisterSearch from "../components/PhotoListItemRegisterSearch";
import PhotoListItemRegisterSeearchSuggestion from "../components/PhotoListItemRegisterSearchSuggestion";
import InformationBox from "../components/InformationBox";
import ActionButton from "../components/ActionButton";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";

export const clubAttendanceByOpportunityID = /* GraphQL */ `
  query ClubAttendanceByOpportunityID(
    $opportunityID: ID!
    $recordedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubAttendanceByOpportunityID(opportunityID: $opportunityID, recordedDate: $recordedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendanceMark
        recordedDate
        userID
        User {
          id
          surname
          firstName
          preferredFirstName
          preferredSurname
          aaa
          title
          type
          photoURL
          dateOfBirth
          email
          gender
          isActive
          pushToken
          createdAt
          updatedAt
          Enrolments {
            items {
              groupingData1
              groupingData2
              groupingData3
            }
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        opportunityID
        Opportunity {
          name
          term
        }
        recordedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const createClubAttendance = /* GraphQL */ `
  mutation CreateClubAttendance($input: CreateClubAttendanceInput!, $condition: ModelClubAttendanceConditionInput) {
    createClubAttendance(input: $input, condition: $condition) {
      id
      attendanceMark
      recordedDate
      userID
      opportunityID
      recordedByID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RecordedBy {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

const ActivitiesCreateSession = ({ route }) => {
  const { opportunityID, name, date } = route.params;
  const colorScheme = useColorScheme();
  const today = new Date();
  const niceDate = prettyDate(today);
  const formattedDate = today.toISOString().slice(0, 10);
  const navigation = useNavigation();
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const userDetailsFromUserContext = useContext(UserContext);
  const { width } = useWindowDimensions();

  const { allClubAttendanceForThisClub, updateClubAttendanceContext } = useContext(ClubAttendanceContext);

  const clubAttendanceFromContext = allClubAttendanceForThisClub;

  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);

  const allUsersFilteredByType = allUserDetailsFromAllUsersContext.filter((user) => user.type === "CHILD");
  const [addMoreAndPresentSectionsVisble, setAddMoreAndPresentSectionsVisble] = useState(true);
  const [filteredData, setFilteredData] = useState(allUsersFilteredByType);
  const [masterData, setMasterData] = useState(allUsersFilteredByType);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const numberOfColumns = width > 1700 ? 5 : width > 1330 ? 4 : width > 1000 ? 3 : width > 680 ? 2 : 1;
  const [filteredWithoutAdded, setFilteredWithoutAdded] = useState([]);
  const [messageForInfoBox, setMessageForInfoBox] = useState(`You are creating a new register for ${name} on:`);
  const [isSearchAndSuggestionSectionVisible, setIsSearchAndSuggestionSectionVisible] = useState(true);
  const [isPupilsPresentSectionVisible, setIsPupilsPresentSectionVisible] = useState(false);
  const [recordedDateOfActivity, setRecordedDateOfActivity] = useState(formattedDate);
  const [anyEditsMade, setAnyEditsMade] = useState(false);

  const [saveButtonPressed, setSaveButtonPressed] = useState(false);

  const NavigateToListOfPreviousSessions = () => {
    navigation.navigate("ActivitiesPreviousSessions");
  };

  const recordsForSelectedDate = clubAttendanceFromContext.filter((item) => item.recordedDate === recordedDateOfActivity);

  console.log("recordsForSelectedDate", recordsForSelectedDate);

  const checkWhatTheInformationBoxShouldSay = () => {
    if (recordsForSelectedDate.length > 0) {
      setIsPupilsPresentSectionVisible(false);
      setIsSearchAndSuggestionSectionVisible(false);
      setAddMoreAndPresentSectionsVisble(false);
      setMessageForInfoBox(`There is already a register for ${name} on:`);
    } else {
      setIsPupilsPresentSectionVisible(true);
      setIsSearchAndSuggestionSectionVisible(true);
      setAddMoreAndPresentSectionsVisble(true);
      setMessageForInfoBox(`You are creating a new register for ${name} on:`);
    }
  };

  useEffect(() => {
    checkWhatTheInformationBoxShouldSay();
  }, [recordedDateOfActivity]);

  const findUniqueUsers = () => {
    if (clubAttendanceFromContext) {
      console.log("clubAttendanceFromContext", clubAttendanceFromContext);

      // Extract unique users using a Set
      const uniqueUsersSet = new Set(clubAttendanceFromContext.map((item) => item.User.id));

      // Create an array of unique user information
      const uniqueUsersArray = Array.from(uniqueUsersSet).map((userId) => {
        const user = clubAttendanceFromContext.find((item) => item.User.id === userId).User;
        return user;
      });

      // Set the state with unique user information
      setUniqueUsers(uniqueUsersArray);
    }
  };

  useEffect(() => {
    findUniqueUsers();
  }, [clubAttendanceFromContext]);

  const handleAddItem = (itemId) => {
    // Check if the item with itemId is already in filteredAttendance
    const isItemExist = filteredAttendance.some((item) => item.id === itemId);

    // If the item doesn't exist in filteredAttendance, add it
    if (!isItemExist) {
      // Find the item from allUserDetailsFromAllUsersContext using itemId
      const newItem = allUserDetailsFromAllUsersContext.find((user) => user.id === itemId);
      // Log the entire record being added
      console.log("Record being added:", newItem);
      // Update the state with the new item added to filteredAttendance
      setFilteredAttendance([...filteredAttendance, newItem]);
      setAnyEditsMade(true);
      setIsPupilsPresentSectionVisible(true);
    }
    console.log('filteredAttendance', filteredAttendance)
  };

  const handleDeleteItem = (itemId) => {
    console.log(itemId);

    if (itemId) {
      const updatedAttendance = filteredAttendance.filter((item) => item.id !== itemId);
      setFilteredAttendance(updatedAttendance);

      setAnyEditsMade(true);
    } else {
      console.log("Item not found");
    }
  };

  // Compare filteredAttendance and uniqueUsers to get suggestedUsers
  useEffect(() => {
    const suggested = uniqueUsers.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setSuggestedUsers(suggested);
  }, [filteredAttendance, uniqueUsers]);

  // Compare filteredAttendance and filteredData to get filteredwithoutadded
  useEffect(() => {
    const searchFilteredWithoutAdded = filteredData.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setFilteredWithoutAdded(searchFilteredWithoutAdded);
  }, [filteredAttendance, filteredData]);

  const SaveRegister = async () => {
    // console.log("filteredAttendance", filteredAttendance);

    setSaveButtonPressed(true);

    try {
      let counter = 0;
      // Iterate through each item in filteredAttendance array
      for (const item of filteredAttendance) {
        counter = counter + 1;

        // Prepare input for GraphQL mutation
        const input = {
          input: {
            attendanceMark: "P",
            recordedDate: recordedDateOfActivity, // Date recorded should be current date
            userID: item.id, // User ID from filteredAttendance item
            opportunityID: opportunityID, // Opportunity ID from route.params
            recordedByID: userDetailsFromUserContext.id, //from context
          },
        };

        // Perform GraphQL mutation
        const result = await API.graphql(graphqlOperation(createClubAttendance, input));

        // Log the result (you can handle this as per your requirement)
        console.log("Mutation result:", result);
      }
      // Generate the alert message
      let alertMessage = `${counter} attendance marks have been saved`;

      if (Platform.OS === "web") {
        alert(alertMessage);
      } else {
        Alert.alert(alertMessage);
      }
      // Reset anyEditsMade state if needed
      setAnyEditsMade(false);

      const fetchData = async () => {
        try {
          const response = await API.graphql(
            graphqlOperation(clubAttendanceByOpportunityID, {
              opportunityID: opportunityID,
              filter: {
                attendanceMark: { eq: "P" },
              },
            })
          );

          if (response) {
            updateClubAttendanceContext(response.data.clubAttendanceByOpportunityID.items);
          }
        } catch (error) {
          console.error("Error fetching data on ActivitiesPreviousSessions screen:", error);
        }
      };

      fetchData();
      setSaveButtonPressed(true);
      NavigateToListOfPreviousSessions();
    } catch (error) {
      console.error("Error saving register:", error);
    }
  };

  const SaveButtonListFooter = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          //  width:'100%',
          alignItems: "center",
        }}>
        {anyEditsMade && !saveButtonPressed && <ActionButton icon={"check-square"} text={"Save this register"} onPressFunction={SaveRegister} />}
        {saveButtonPressed && <ActivityIndicatorAnimation />}
      </View>
    );
  };

  return (
    <ScrollView contentContainerStyle={{ backgroundColor: Colours[colorScheme].background, flexGrow: 1 }}>
        
      <View style={{ flex: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "center" }}>
        {/* <ScrollView
        style={{
          
          flexGrow: 1
        }}> */}

        <InformationBox message={messageForInfoBox} date={recordedDateOfActivity} setDate={setRecordedDateOfActivity} />
        {addMoreAndPresentSectionsVisble && (
          <View
            style={{
              width: "100%",
              backgroundColor: "transparent",
              flex: 1,
              paddingHorizontal: 10,
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Pressable
              onPress={() => {
                VibrateOnce();

                setIsSearchAndSuggestionSectionVisible(!isSearchAndSuggestionSectionVisible);
              }}
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                backgroundColor: Colours[colorScheme].almostBackground,
              }}>
              <Feather name={isSearchAndSuggestionSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

              <BoldText style={{ color: Colours[colorScheme].text }}>{filteredAttendance.length === 0 ? "Add some pupils" : "Add more pupils"}</BoldText>
            </Pressable>

            {isSearchAndSuggestionSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}>
                {/* search bar */}

                <SearchBar masterData={masterData} filteredData={filteredData} setFilteredData={setFilteredData} />

                <View style={{ backgroundColor: "transparent", height: 150 }}>
                  {filteredData.length < masterData.length ? (
                    <FlatList data={filteredWithoutAdded} horizontal renderItem={({ item }) => <PhotoListItemRegisterSearch item={item} onPressToAdd={handleAddItem} />} />
                  ) : (
                    <>
                      <FlatList data={suggestedUsers} horizontal renderItem={({ item }) => <PhotoListItemRegisterSeearchSuggestion item={item} onPressToAdd={handleAddItem} />} />
                    </>
                  )}
                </View>
              </View>
            )}

            {!isSearchAndSuggestionSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}></View>
            )}

            <Pressable
              onPress={() => {
                VibrateOnce();

                setIsPupilsPresentSectionVisible(!isPupilsPresentSectionVisible);
              }}
              style={{
                borderTopLeftRadius: 10,
                alignItems: "center",
                flexDirection: "row",
                borderTopRightRadius: 10,
                width: "100%",
                padding: 10,
                backgroundColor: Colours[colorScheme].almostBackground,
              }}>
              <Feather name={isPupilsPresentSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

              <BoldText style={{ color: Colours[colorScheme].text }}>
                {filteredAttendance.length} {filteredAttendance.length === 1 ? "pupil" : "pupils"} present
              </BoldText>
            </Pressable>

            {isPupilsPresentSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "50%",
                  flex: 1,
                  borderBottomRightRadius: 10,
                  width: "100%",
                  // alignItems:'center',
                  backgroundColor: Colours[colorScheme].background,
                }}>
                {filteredAttendance.length === 0 ? <Text style={{ color: Colours[colorScheme].text, padding: 5 }}>No one is here. 🙁</Text> : null}
                {/* <SaveButtonListFooter /> */}
                <FlatList
                  data={filteredAttendance}
                  renderItem={({ item }) => <PhotoListItemRegister item={item} onPressDelete={handleDeleteItem} />}
                  numColumns={numberOfColumns}
                  // scrollEnabled={false}
                  style={numberOfColumns > 1 ? null : { alignSelf: "center" }}
                  // columnWrapperStyle={numberOfColumns > 1 ? { justifyContent:'flex-start' } : null}
                  key={numberOfColumns}
                  ListHeaderComponent={<SaveButtonListFooter />}
                />
              </View>
            )}

            {!isPupilsPresentSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}></View>
            )}
          </View>
        )}
        {/* </ScrollView> */}
      </View>
      </ScrollView>
  );
};

export default ActivitiesCreateSession;

const styles = StyleSheet.create({});
