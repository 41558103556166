import React from "react";
import Svg, { Path } from "react-native-svg";

const CriticalThinkingIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500" >
      <Path  d="M344.7,142.9c-22.5-22.7-52.3-35.5-84-36.1c-27.6-0.5-54.8,8.6-76.6,25.5c-21.3,16.6-36.7,40-43.3,66
	c0,0.1-0.1,0.3-0.1,0.4l-20.2,66.4c-3.3,10.8,0.7,22.4,9.9,29l6.7,4.8v26.9c0,36.5,29.7,66.3,66.3,66.3h138.8h12.5V380l2.6-38.2
	c1.6-23.6,6.9-48,15.7-72.6c4.8-13.4,7.2-27.5,7-41.9C379.7,195.6,367.1,165.6,344.7,142.9z M354.9,227.5
	c0.1,11.4-1.8,22.6-5.6,33.2c-9.6,26.8-15.3,53.5-17.1,79.3l-1.9,27h-127c-22.7,0-41.3-18.5-41.3-41.3v-39.8l-17.2-12.3
	c-0.4-0.3-0.6-0.8-0.4-1.3c0,0,11.6-38,17.3-56.9l0.5,0.1l2.8-11c5.2-20.7,17.4-39.4,34.4-52.6c17.5-13.6,38.6-20.6,60.8-20.2
	C312,132.8,354.5,175.7,354.9,227.5L354.9,227.5z M307.4,232.5c0-2.1-0.1-4.1-0.4-6.1l13.6-15.3l-3.4-7.4c-1.8-3.8-3.9-7.5-6.3-10.9
	l-4.7-6.7l-20,4.2c-3.3-2.4-6.8-4.5-10.6-6.1l-6.4-19.4l-8.1-0.8c-4.1-0.4-8.5-0.4-12.6,0l-8.1,0.8l-6.4,19.4
	c-3.8,1.6-7.3,3.7-10.6,6.1l-20-4.2l-4.7,6.7c-2.4,3.4-4.6,7.1-6.3,10.9l-3.4,7.4l13.6,15.3c-0.2,2-0.4,4.1-0.4,6.1
	c0,2.1,0.1,4.1,0.4,6.1L189,253.9l3.4,7.4c1.7,3.8,3.9,7.5,6.3,10.9l4.7,6.7l20-4.2c3.3,2.4,6.8,4.5,10.6,6.1l6.4,19.4l8.2,0.8
	c2.1,0.2,4.2,0.3,6.3,0.3s4.2-0.1,6.3-0.3l8.2-0.8l6.4-19.4c3.8-1.6,7.3-3.7,10.6-6.1l20,4.2l4.7-6.7c2.4-3.4,4.6-7.1,6.3-10.9
	l3.4-7.4l-13.6-15.3C307.3,236.6,307.4,234.5,307.4,232.5z M227.9,232.5c0-14.8,12-26.8,26.8-26.8s26.8,12,26.8,26.8
	s-12,26.8-26.8,26.8S227.9,247.3,227.9,232.5z" fill={'#4B65D6'}/>

	

    
    </Svg>
    
        
  );
};

export default CriticalThinkingIcon;