import { Pressable, ScrollView, StyleSheet} from 'react-native'
import {View, Text} from '../constants/themedViewAndText'
import React, { useContext, useState } from 'react'
import { UserContext, AllUsersContext } from '../hooks/Contexts';
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import { Feather } from '@expo/vector-icons';
import { BoldText } from '../constants/styledText';
import { Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react-native';
import AchievementsTabNavigator from '../navigation/achievementsTabs';
import PointsDashboard from '../components/PointsDashboard';
import CrewNominationsLink from '../components/CrewNominationsLink';
import { useNavigation } from "@react-navigation/native";
import ActionButton from '../components/ActionButton';
import ClubAttendanceComponent from '../components/ActivityAttendanceDashboardWidget';

const HomeScreen = () => {
  const colorScheme = useColorScheme();
  const navigation = useNavigation()
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext)
  const type = userDetailsFromUserContext.type

  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const { signOut } = useAuthenticator();

  const NavigateToAwardNewBadge = () => {
    navigation.navigate("AwardNewBadge", { });
  };
   

  return (
    <View style={{backgroundColor:Colours[colorScheme].background, flex:1}}>
    <ScrollView style={{flex:1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius:10}}>
  
      <CrewNominationsLink />

      <ClubAttendanceComponent />

      <PointsDashboard />

    

      {/* {type === 'ADMIN'?<><View style={{alignSelf:'center', backgroundColor:'transparent'}}>
      <ActionButton icon={'plus-circle'} text={'Award new badge'} onPressFunction={NavigateToAwardNewBadge} />
      </View>
</>:null} */}
    </ScrollView>
    </View>
  )
}

export default HomeScreen

const styles = StyleSheet.create({})