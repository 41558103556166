import React, { useContext, useEffect, useState } from "react";
import { Alert, FlatList, Platform, Pressable, ScrollView, StyleSheet, TextInput, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import HouseLogo from "../components/HouseLogo";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import { VibrateOnce } from "../functions/vibration";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import { Picker } from "@react-native-picker/picker";
import TimelineIcon from "../../assets/icons/Timeline";
import RenderTextInput from "../components/RenderTextInput";
import AccountDetailsForm from "../components/AccountDetailsForm";
import EnrolmentDetailsForm from "../components/EnrolmentDetailsForms";
import BackButton from "../components/BackButton";
import SearchBar from "../components/SearchBar";
import PhotoListItemRegisterSearch from "../components/PhotoListItemRegisterSearch";
import PhotoListItemRegister from "../components/PhotoListItemRegister";
import PickADate from "../components/PickADate";
import RenderMultiLineTextInput from "../components/RenderMultiLineTextInput";
import { Image } from "expo-image";
import { useNavigation } from "@react-navigation/native";
import { fetchUsersFromDatabase } from "../functions/getAllUsers";

import CollaborationIcon from '../../assets/icons/collaboration';
import InfluenceIcon from '../../assets/icons/influence';
import CommunicationIcon from '../../assets/icons/communication';
import AmbitionIcon from '../../assets/icons/ambition';
import CriticalThinkingIcon from '../../assets/icons/criticalthinking';
import InitiativeIcon from '../../assets/icons/initiative';
import CommitmentIcon from '../../assets/icons/commitment';
import SelfAwarenessIcon from '../../assets/icons/selfawareness';
import CourageIcon from '../../assets/icons/courage';
import InclusivenessIcon from '../../assets/icons/inclusiveness';
import CompassionIcon from '../../assets/icons/compassion';
import IntegrityIcon from '../../assets/icons/integrity';


export const createOpportunity = /* GraphQL */ `
  mutation CreateOpportunity($input: CreateOpportunityInput!, $condition: ModelOpportunityConditionInput) {
    createOpportunity(input: $input, condition: $condition) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mappings {
        nextToken
        startedAt
        __typename
      }
      OpportunityTagMapping {
        nextToken
        startedAt
        __typename
      }
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      AwardedBadges {
        nextToken
        startedAt
        __typename
      }
      OpportunityAvailabilities {
        nextToken
        startedAt
        __typename
      }
      availableTo
      year
      term
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const createAwardedBadge = /* GraphQL */ `
  mutation CreateAwardedBadge($input: CreateAwardedBadgeInput!, $condition: ModelAwardedBadgeConditionInput) {
    createAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const createMapping = /* GraphQL */ `
  mutation CreateMapping(
    $input: CreateMappingInput!
    $condition: ModelMappingConditionInput
  ) {
    createMapping(input: $input, condition: $condition) {
      id
      level
      criteriaID
      opportunityID
      Criteria {
        id
        name
        aaa
        description
        logoColourURI
        colour
        strapline
        categoryName
        categoryStrapline
        categoryLogoURL
        videoURL
        synonyms
        level1Description
        level2Description
        level3Description
        level4Description
        level5Description
        level6Description
        logoWhiteURI
        logoGreyURI
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;


const AwardNewBadge = ({ route }) => {
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  const [isEnrolmentSectionVisible, setIsEnrolmentSectionVisible] = useState(true);

  // const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);

  const [allUsers, setAllUsers] = useState([]);

  const { width } = useWindowDimensions();
  // const allUsersFilteredByType = allUserDetailsFromAllUsersContext.filter((user) => user.type === "CHILD");
  // const allUsersFilteredByType = allUsers.filter((user) => user.type === "CHILD");

  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [filteredWithoutAdded, setFilteredWithoutAdded] = useState([]);
  const numberOfColumns = width > 1700 ? 5 : width > 1330 ? 4 : width > 1000 ? 3 : width > 680 ? 2 : 1;
  const [addMoreAndPresentSectionsVisble, setAddMoreAndPresentSectionsVisble] = useState(true);
  const [isSearchAndSuggestionSectionVisible, setIsSearchAndSuggestionSectionVisible] = useState(true);
  const [isPupilsPresentSectionVisible, setIsPupilsPresentSectionVisible] = useState(false);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [anyEditsMade, setAnyEditsMade] = useState(false);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [isBadgeDetailsSectionVisible, setIsBadgeDetailsSectionVisible] = useState(true);
  const thisUser = useContext(UserContext);
  const [date, setDate] = useState(formattedDate);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false)

  const iconComponents = {
    Collaboration: CollaborationIcon,
    Influence: InfluenceIcon,
    Communication: CommunicationIcon,
    Ambition: AmbitionIcon,
    "Critical-thinking": CriticalThinkingIcon,
    Initiative: InitiativeIcon,
    Commitment: CommitmentIcon,
    "Self-awareness": SelfAwarenessIcon,
    Courage: CourageIcon,
    Inclusiveness: InclusivenessIcon,
    Compassion: CompassionIcon,
    Integrity: IntegrityIcon,
  };
  


  const characterAttributes = [
    "Collaboration",
    "Influence",
    "Communication",
    "Ambition",
    "Critical-thinking",
    "Initiative",
    "Commitment",
    "Self-awareness",
    "Courage",
    "Inclusiveness",
    "Compassion",
    "Integrity",
  ];
  const CHARACTER_ATTRIBUTE_IDS = {
    'Integrity': 'lca01',
    'Inclusiveness': 'lca02',
    'Compassion': 'lca03',
    'Influence': 'lca04',
    'Collaboration': 'lca05',
    'Communication': 'lca06',
    'Commitment': 'lca07',
    'Courage': 'lca08',
    'Self-awareness': 'lca09',
    'Ambition': 'lca10',
    'Critical-thinking': 'lca11',
    'Initiative': 'lca12'
  } as const;
  const [selectedCharacterAttribute, setSelectedCharacterAttributes] = useState<string[]>([]); // Tracks all selected items
  const [characterAttributeStates, setCharacterAttributeStates] = useState<{ [key: string]: boolean }>({}); // Dynamic object for toggle state

  const [opportunityCategories, setOpportunityCategories] = useState([]);

  const [nameOfBadge, setNameOfBadge] = useState<string>("");
  const [descriptionOfBadge, setDescriptionOfBadge] = useState<string>("");

  const NavigateToHomeScreen = () => {
    navigation.navigate("Home");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersList = await fetchUsersFromDatabase();

        const allUsersFilteredByType = usersList.filter((user) => user.type === "CHILD");

        setFilteredData(allUsersFilteredByType);
        setMasterData(allUsersFilteredByType);
      } catch (err) {
        console.log(err.message); // Handle error
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const toggleSelection = (item: string) => {
    // Toggle the item's state
    setCharacterAttributeStates((prevState) => ({
      ...prevState,
      [item]: !prevState[item], // Toggle the specific item's state
    }));

    // Add/remove item from selectedItems array
    setSelectedCharacterAttributes(
      (prevSelectedItems) =>
        prevSelectedItems.includes(item)
          ? prevSelectedItems.filter((i) => i !== item) // Remove item if it's already selected
          : [...prevSelectedItems, item] // Add item if not selected
    );
  };

  const handleDeleteItem = (itemId) => {
    console.log(itemId);

    if (itemId) {
      const updatedAttendance = filteredAttendance.filter((item) => item.id !== itemId);
      setFilteredAttendance(updatedAttendance);

      setAnyEditsMade(true);
    } else {
      console.log("Item not found");
    }
  };

  console.log("thisUser", thisUser.id);

  // Compare filteredAttendance and uniqueUsers to get suggestedUsers
  // useEffect(() => {
  //   const suggested = uniqueUsers.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
  //   setSuggestedUsers(suggested);
  // }, [filteredAttendance, uniqueUsers]);

  // Compare filteredAttendance and filteredData to get filteredwithoutadded
  useEffect(() => {
    const searchFilteredWithoutAdded = filteredData.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setFilteredWithoutAdded(searchFilteredWithoutAdded);
  }, [filteredAttendance, filteredData]);

  const SaveRegister = async () => {
    setSaveButtonClicked(true)
    console.log("save button pressed");
    setIsPupilsPresentSectionVisible(false)
    setIsBadgeDetailsSectionVisible(false)
    setIsSearchAndSuggestionSectionVisible(false)

    if (isFormComplete()) {
      //organise opportunity data input to upload
      const opportunityInput = {
        input: {
          isActive: true,
          name: nameOfBadge, // Collected badge name from form
          description: descriptionOfBadge, // Collected badge description from form
          engagementLevel: 0,
          dateType: "ONEOFFDATE",
          oneOffDate: date,
          userID: thisUser.id, // The user creating the badge
          opportunitycategoryID: "00000000-0000-0000-0000-000000000000",
          totalHours: 1, // Assuming this is 0 initially, adjust as needed
        },
      };
      console.log("opportunityInput", opportunityInput);

      // Perform GraphQL mutation
      const opportunityResult = await API.graphql(graphqlOperation(createOpportunity, opportunityInput));

      // Log the result
      console.log("Mutation result:", opportunityResult.data.createOpportunity.id);









    // Convert characterAttributeStates object to array of selected attributes
    const selectedAttributes = Object.entries(characterAttributeStates)
      .filter(([_, isSelected]) => isSelected)
      .map(([attribute]) => attribute);

    // Create mapping for each selected character attribute
    for (const attribute of selectedAttributes) {
      const tagId = CHARACTER_ATTRIBUTE_IDS[attribute];
      if (!tagId) {
        console.error(`No matching ID found for attribute: ${attribute}`);
        continue;
      }

      const mappingInput = {
        input: {
          opportunityID: opportunityResult.data.createOpportunity.id,
          level: 1,
          criteriaID: tagId,
          isActive:true,
        },
      };

      console.log('mappingInput',mappingInput)

      try {
        const mappingResult = await API.graphql(
          graphqlOperation(createMapping, mappingInput)
        );
        console.log(`Created mapping for ${attribute} (${tagId}):`, mappingResult);
      } catch (error) {
        console.error(`Error creating mapping for ${attribute} (${tagId}):`, error);
      }
    }
















      let counter = 0;

      // Iterate through each item in filteredAttendance array
      for (const item of filteredAttendance) {
        counter = counter + 1;

        console.log("item", item);

        // Prepare input for GraphQL mutation
        const awardedBadgeInput = {
          input: {
            dateAwarded: formattedDate, //badge was awarded today
            groupingData1Value: item.Enrolments.items[0]?.groupingData1 ?? 'No house data provided', // Safe default if undefined/null
            groupingData2Value: item.Enrolments.items[0]?.groupingData2 ?? 'No coach data provided', // Safe default if undefined/null
            groupingData3Value: item.Enrolments.items[0]?.groupingData3 ?? 'No year data provided', // Safe default if undefined/null
            userID: item.id, // User ID from filteredAttendance item
            scoringData3Value: 0, //no house points at this stage
            opportunityID: opportunityResult.data.createOpportunity.id, // Opportunity ID from result of previous mutation
          },
        };

        console.log("awardedBadgeInput", awardedBadgeInput);

       // Perform GraphQL mutation
        const result = await API.graphql(graphqlOperation(createAwardedBadge, awardedBadgeInput));

        //Log the result (you can handle this as per your requirement)
        console.log("Mutation result:", result);
      }
      // Generate the alert message
      let alertMessage = `${counter} attendance marks have been saved`;

      if (Platform.OS === "web") {
        alert(alertMessage);
      } else {
        Alert.alert(alertMessage);
      }

      NavigateToHomeScreen();

      //clear form
      setNameOfBadge("");
      setDescriptionOfBadge("");
      setDate(formattedDate);
      setCharacterAttributeStates({});
      setFilteredAttendance([]);
      setSaveButtonClicked(false)
    }
  };

  const SaveButtonListFooter = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          //  width:'100%',
          alignItems: "center",
        }}>
        {anyEditsMade && filteredAttendance.length > 0 && isFormComplete() && !saveButtonClicked && <ActionButton icon={"check-square"} text={"Award this badge"} onPressFunction={SaveRegister} />}
      {saveButtonClicked && <ActivityIndicatorAnimation/>}
      </View>
    );
  };

  // Function to check if all required fields are filled
  const isFormComplete = () => {
    return nameOfBadge !== "" && descriptionOfBadge !== "" && date !== "" && filteredAttendance.length > 0;
  };

  const handleAddItem = (itemId) => {
    // Check if the item with itemId is already in filteredAttendance
    const isItemExist = filteredAttendance.some((item) => item.id === itemId);

    // If the item doesn't exist in filteredAttendance, add it
    if (!isItemExist) {
      // Find the item from allUserDetailsFromAllUsersContext using itemId
      const newItem = filteredData.find((user) => user.id === itemId);
      // Log the entire record being added
      console.log("Record being added:", newItem);
      // Update the state with the new item added to filteredAttendance
      setFilteredAttendance([...filteredAttendance, newItem]);
      setAnyEditsMade(true);
      setIsPupilsPresentSectionVisible(true);
    }
  };

  console.log("route", route);

  return (
    <View style={{ flex: 1, backgroundColor: Colours[colorScheme].background }}>
      
        <View style={{ backgroundColor: Colours[colorScheme].background, height: 40 }}>
          <BackButton />
        </View>
        <ScrollView contentContainerStyle={{ backgroundColor: Colours[colorScheme].background, flexGrow: 1 }}>
        <View style={{ flex: 1, width: "100%", padding: 20, backgroundColor: Colours[colorScheme].practicallyBackground, borderRadius: 10, alignItems: "center" }}>
          <View
            style={{
              width: "100%",
              backgroundColor: "transparent",
              flex: 1,
              paddingHorizontal: 10,
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Pressable
              onPress={() => {
                VibrateOnce();

                setIsBadgeDetailsSectionVisible(!isBadgeDetailsSectionVisible);
              }}
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                backgroundColor: Colours[colorScheme].almostBackground,
              }}>
              <Feather name={isBadgeDetailsSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

              <BoldText style={{ color: Colours[colorScheme].text }}>Badge details</BoldText>
            </Pressable>

            {isBadgeDetailsSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}>
                <RenderTextInput label={"Name of badge"} value={nameOfBadge} onChange={setNameOfBadge} />
                <RenderMultiLineTextInput label={"Description of activity"} value={descriptionOfBadge} onChange={setDescriptionOfBadge} />

                <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                    <Text>Date:</Text>
                  </View>
                  <View style={{ backgroundColor: "transparent", width: "50%" }}>
                    <View
                      style={{
                        padding: 10,
                        margin: 10,
                        flexDirection: "row",
                        backgroundColor: Colours[colorScheme].almostBackground,
                        borderColor: Colours.palette.logoGreen,
                        borderWidth: 1,
                        borderRadius: 8,
                        alignItems: "center",
                      }}>
                      <PickADate date={date} setDate={setDate} />
                    </View>
                  </View>
                </View>

                <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                  <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                    <Text>Opportunity to develop character:</Text>
                  </View>
                  <View style={{ backgroundColor: "transparent", width: "50%" }}>
                    <View
                      style={{
                        padding: 10,
                        margin: 10,
                        flexDirection: "row",
                        backgroundColor: Colours[colorScheme].almostBackground,
                        borderColor: Colours.palette.logoGreen,
                        borderWidth: 1,
                        borderRadius: 8,
                        alignItems: "center",
                        flexWrap: "wrap", // Allows wrapping of items to form rows
                        // justifyContent: 'space-between', // Distribute space evenly
                      }}>
                      {characterAttributes.map((item) => {
                      
                      const IconComponent = iconComponents[item]; // Get the corresponding SVG component
                      return (
                      
                      
                        
                        <Pressable
                          style={{
                            backgroundColor: characterAttributeStates[item] ? Colours[colorScheme].background : "transparent",
                            borderWidth: characterAttributeStates[item] ? 1 : 0,
                            borderColor: characterAttributeStates[item] ? Colours.palette.logoGreen : "transparent",
                            borderRadius: 8,
                            alignItems: "center",
                            width: 100,
                            height: 100,
                          }}
                          key={item}
                          onPress={() => toggleSelection(item)}>
                          <View style={{ backgroundColor: "transparent", alignItems: "center", justifyContent: "center", borderRadius: 50, width: 60, height: 60, margin: 5 }}>
                            {/* <Image source={require(`../../assets/images/${item}_colour.png`)} contentFit="contain" style={{ width: 45, height: 45 }} /> */}
                            {IconComponent ? <View style={{backgroundColor:'transparent', width:55, height:55, justifyContent:'center', alignItems:'center'}}><IconComponent /></View> : null}
                          </View>
                          <Text style={{ fontSize: 10 }}>{item}</Text>
                        </Pressable>
                      )})}
                    </View>
                  </View>
                </View>
              </View>
            )}

            {!isBadgeDetailsSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}></View>
            )}

            <Pressable
              onPress={() => {
                VibrateOnce();

                setIsSearchAndSuggestionSectionVisible(!isSearchAndSuggestionSectionVisible);
              }}
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                backgroundColor: Colours[colorScheme].almostBackground,
              }}>
              <Feather name={isSearchAndSuggestionSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

              <BoldText style={{ color: Colours[colorScheme].text }}>Add some pupils</BoldText>
            </Pressable>

            {isSearchAndSuggestionSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}>
                {/* search bar */}

                <SearchBar masterData={masterData} setFilteredData={setFilteredData} filteredData={filteredData} />

                <View style={{ backgroundColor: "transparent", height: 150 }}>
                  {isLoading ? (
                    <View style={{backgroundColor:'transparent', alignItems:'center', flex:1,width:'100%', justifyContent:'center'}}><ActivityIndicatorAnimation /></View>
                  ) : (
                    <FlatList data={filteredWithoutAdded} horizontal renderItem={({ item }) => <PhotoListItemRegisterSearch item={item} onPressToAdd={handleAddItem} />} />
                  )}
                </View>
              </View>
            )}

            {!isSearchAndSuggestionSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}></View>
            )}

            <Pressable
              onPress={() => {
                VibrateOnce();

                setIsPupilsPresentSectionVisible(!isPupilsPresentSectionVisible);
              }}
              style={{
                borderTopLeftRadius: 10,
                alignItems: "center",
                flexDirection: "row",
                borderTopRightRadius: 10,
                width: "100%",
                padding: 10,
                backgroundColor: Colours[colorScheme].almostBackground,
              }}>
              <Feather name={isPupilsPresentSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

              <BoldText style={{ color: Colours[colorScheme].text }}>
                {filteredAttendance.length} {filteredAttendance.length === 1 ? "pupil" : "pupils"} will be awarded this badge
              </BoldText>
            </Pressable>

            {isPupilsPresentSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "50%",
                  flex: 1,
                  borderBottomRightRadius: 10,
                  width: "100%",
                  // alignItems:'center',
                  backgroundColor: Colours[colorScheme].background,
                }}>
                {filteredAttendance.length === 0 ? <Text style={{ color: Colours[colorScheme].text, padding: 5 }}>No one is here. 🙁</Text> : null}
                {/* <SaveButtonListFooter /> */}
                <FlatList
                  data={filteredAttendance}
                  renderItem={({ item }) => <PhotoListItemRegister item={item} onPressDelete={handleDeleteItem} />}
                  numColumns={numberOfColumns}
                  // scrollEnabled={false}
                  style={numberOfColumns > 1 ? null : { alignSelf: "center" }}
                  // columnWrapperStyle={numberOfColumns > 1 ? { justifyContent:'flex-start' } : null}
                  key={numberOfColumns}
                  ListHeaderComponent={<SaveButtonListFooter />}
                />
              </View>
            )}

            {!isPupilsPresentSectionVisible && (
              <View
                style={{
                  marginBottom: 10,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  // height: "60%",
                  borderBottomRightRadius: 10,
                  width: "100%",
                  backgroundColor: Colours[colorScheme].background,
                }}></View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default AwardNewBadge;

const styles = StyleSheet.create({});
