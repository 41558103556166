import React, { useContext, useEffect, useState } from "react";
import { Alert, Image, Platform, Pressable, ScrollView, StyleSheet, TextInput } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import HouseLogo from "../components/HouseLogo";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import { VibrateOnce } from "../functions/vibration";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import { Picker } from "@react-native-picker/picker";
import TimelineIcon from "../../assets/icons/Timeline";
import RenderTextInput from "../components/RenderTextInput";
import AccountDetailsForm from "../components/AccountDetailsForm";
import EnrolmentDetailsForm from "../components/EnrolmentDetailsForms";

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      pushToken
      Enrolments {
        nextToken
        startedAt
        __typename
      }
      Opportunities {
        nextToken
        startedAt
        __typename
      }
      AwardedBadges {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
        __typename
      }
      MomentsContributed {
        nextToken
        startedAt
        __typename
      }
      MomentsActuallUploaded {
        nextToken
        startedAt
        __typename
      }
      Tags {
        nextToken
        startedAt
        __typename
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      AttendanceMarksRecordedBy {
        nextToken
        startedAt
        __typename
      }
      DailyPointsTotals {
        nextToken
        startedAt
        __typename
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
      }
      DeliveredTrainings {
        nextToken
        startedAt
        __typename
      }
      TrainingAttendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      Enrolments {
        items {
          contextData1
          contextData2
          contextData3
          groupingData1
          groupingData2
          groupingData3
          scoringData1
          scoringData2
          scoringData3
          _version
        }
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        items {
          id
          recordedDate
          opportunityID
          Opportunity {
            name
            logoURL
          }
        }
      }
      AwardedBadges {
        nextToken
        startedAt
        items {
          id
          scoringData3Value
          dateAwarded
          opportunityID
          Opportunity {
            id
            isActive
            name
            aaa
            colour
            logoURL
            oneOffDate
            multipleStartDate
            multipleEndDate
            opportunitycategoryID
          }
        }
      }
      TrainingAttendance {
        nextToken
        startedAt
        items {
          id
          trainingID
          Training {
            id
            venue
            date
            name
            type
            description
          }
        }
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
        items {
          id
          name
          type
          logoURL
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const AdminUserDetails = ({ route }) => {
  const { userID } = route.params;
  const colorScheme = useColorScheme();
  const [userDataFromServer, setUserDataFromServer] = useState({});
  const [userData, setUserData] = useState({});
  const [downloadedImage, setDownloadedImage] = useState();
  // const house = userData ? userData.Enrolments?.items[0]?.groupingData1 : null;
  // const coach = userData ? userData.Enrolments?.items[0]?.groupingData2 : null;
  // const year = userData ? userData.Enrolments?.items[0]?.groupingData3 : null;
  // const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";
  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const [clubAttendanceData, setClubAttendanceData] = useState([]);
  const [trainingAttendanceData, setTrainingAttendanceData] = useState([]);
  const [headlineAchievements, setHeadlineAchievements] = useState([]);
const [enrolmentData, setEnrolmentData] = useState({})
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [isAccountSectionVisible, setIsAccountSectionVisible] = useState(true);

  const [formData, setFormData] = useState({}); // State to track form data
  const [isUserDirty, setIsUserDirty] = useState(false); // State to track if there are unsaved changes
  const [isEnrolmentDirty, setIsEnrolmentDirty] = useState(false); // State to track if there are unsaved changes

  const [isEnrolmentSectionVisible, setIsEnrolmentSectionVisible] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUser, { id: userID }));
      console.log('response',response)
      if (response && response.data && response.data.getUser) {
        setUserDataFromServer(response.data.getUser);

        if (response.data.getUser.AwardedBadges && response.data.getUser.AwardedBadges.items) {
          setAwardedBadgesData(response.data.getUser.AwardedBadges.items);
        } else {
          setAwardedBadgesData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.HeadlineAchievements && response.data.getUser.HeadlineAchievements.items) {
          setHeadlineAchievements(response.data.getUser.HeadlineAchievements.items);
        } else {
          setHeadlineAchievements([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.Enrolments && response.data.getUser.Enrolments.items) {
          setEnrolmentData(response.data.getUser.Enrolments.items[0]);
        } else {
          setEnrolmentData([]); // Set to an empty array if no data is available
        }


        if (response.data.getUser.ClubAttendanceMarks && response.data.getUser.ClubAttendanceMarks.items) {
          setClubAttendanceData(response.data.getUser.ClubAttendanceMarks.items);
        } else {
          setClubAttendanceData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.TrainingAttendance && response.data.getUser.TrainingAttendance.items) {
          setTrainingAttendanceData(response.data.getUser.TrainingAttendance.items);
        } else {
          setTrainingAttendanceData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.photoURL) {
          const file = await Storage.get(response.data.getUser.photoURL, { level: "public" });
          setDownloadedImage(file);
        } else {
          setDownloadedImage(null); // Set to null if no photo URL is available
        }
      } else {
        // Handle the case when the response is empty or doesn't contain the expected data
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, [userID]);


  console.log('enrolmentData',enrolmentData)


  return (
    <ScrollView style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, width: "100%", padding: 20, backgroundColor: Colours[colorScheme].practicallyBackground, borderRadius: 10, alignItems: "center" }}>
        {/* ACCOUNT SECTION */}

        <AccountDetailsForm userDataFromServer={userDataFromServer} downloadedImage={downloadedImage} isLoading={isLoading} userID={userID} />

        {/* ENROLMENT SECTION */}



{enrolmentData?

        <EnrolmentDetailsForm
          enrolmentDataFromServer={enrolmentData}
          isLoading={isLoading}
          userID={userID}
        />:null}
      
      </View>
    </ScrollView>
  );
};

export default AdminUserDetails;

const styles = StyleSheet.create({});
