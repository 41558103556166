import React from "react";
import Svg, { Path } from "react-native-svg";

const AmbitionIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 500 500">
      <Path  d="M439.8,132L60.7,196.8l93.3,48.4l33.4,91.1l43.1-32.7l54.4,57.9L439.8,132z M268.3,186.7L165,222.8l-26.8-13.9
	L268.3,186.7z M317.9,195.8L213,263.5l0,0l-2.3,1.5l-14.4,23l-16.1-44L317.9,195.8z M239.2,276.4l88.2-56.9l41.1-26.6l-86.9,128.7
	L239.2,276.4z M160.7,324l11.2-8.5l-2.2,33.1L160.7,324z M133.5,344.1l20.8-15.8l-0.2,31.5l-11.6,8.8L133.5,344.1z" fill={'#BF1B3D'}/>

	

    
    </Svg>
    
        
  );
};

export default AmbitionIcon;