export function getLastWeeksDates(): {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
  } {
    const today = new Date();
    const dayOfWeek = today.getUTCDay(); // 0 (Sunday) to 6 (Saturday)
  
    // Calculate how many days to go back to find the last Friday of the previous full working week
    const daysToLastFriday = dayOfWeek === 0 ? 2 : dayOfWeek === 6 ? 1 : dayOfWeek + 2;
    const lastFriday = new Date(today);
    lastFriday.setUTCDate(today.getUTCDate() - daysToLastFriday);
  
    // Create an object for the last full working week
    const weekDates: { [key: string]: string } = {};
    const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
  
    // Populate the object with dates for Monday to Friday
    for (let i = 0; i < 5; i++) {
      const date = new Date(lastFriday);
      date.setUTCDate(lastFriday.getUTCDate() - (4 - i));
      weekDates[weekdays[i]] = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    }
  
    return weekDates as {
      monday: string;
      tuesday: string;
      wednesday: string;
      thursday: string;
      friday: string;
    };
  }
  