import { ScrollView, StyleSheet, Platform } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext } from "react";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import WeekdayGrid from "../components/HeatMap";

const FlexibleLearningHeatMap = ({ clubAttendance }) => {
  const colorScheme = useColorScheme();
  // const { closedDates } = useContext(OrganisationContext);
  const today =  new Date();
  const { currentAcademicYear, currentAcademicYearStartDate, organisationID, specialDates } = useContext(OrganisationContext);





// Filter special dates to only include those from the current academic year
const specialDatesForCurrentYear = specialDates.filter(date => date.academicYear === currentAcademicYear);



// Map to new object with only specialDateType and specialDate
const formattedSpecialDates = specialDatesForCurrentYear.map(date => ({
  specialDateType: date.specialDateType,
  specialDate: date.specialDate
}));

// Filter out the 'SchoolClosed' special dates
const closedDates = specialDates
  .filter(date => date.specialDateType === 'SchoolClosed')  // Filter for 'SchoolClosed' type
  .map(date => date.specialDate);  // Extract just the date

console.log(closedDates);




// Find the special date for the specific specialDateType
const autumn2024StartDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "StartOfAutumn1Term")?.specialDate;
const autumn2024EndDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "EndOfAutumn2Term")?.specialDate;
const spring2025StartDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "StartOfSpring1Term")?.specialDate;
const spring2025EndDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "EndOfSpring2Term")?.specialDate;
const summer2025StartDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "StartOfSummer1Term")?.specialDate;
const summer2025EndDateString = specialDatesForCurrentYear.find(date => date.specialDateType === "EndOfSummer2Term")?.specialDate;


// Convert the autumn2024StartDate string to a Date object
const autumn2024StartDate = autumn2024StartDateString ? new Date(autumn2024StartDateString) : null;
const autumn2024EndDate = autumn2024EndDateString ? new Date(autumn2024EndDateString) : null;
const spring2025StartDate = spring2025StartDateString ? new Date(spring2025StartDateString) : null;
const spring2025EndDate = spring2025EndDateString ? new Date(spring2025EndDateString) : null;
const summer2025StartDate = summer2025StartDateString ? new Date(summer2025StartDateString) : null;
const summer2025EndDate = summer2025EndDateString ? new Date(summer2025EndDateString) : null;




  //these dates need moving to organisation context

const summer2024StartDate = new Date("2024-04-15")
const summer2024EndDate = new Date("2024-07-24")

  const spring2024StartDate = new Date("2024-01-08");
  const spring2024EndDate = new Date("2024-03-29");

  const autumn20222023StartDate = new Date("2022-09-05");
  const autumn20222023EndDate = new Date("2022-12-16");

  const spring20222023StartDate = new Date("2023-01-01");
  const spring20222023EndDate = new Date("2023-03-31");

  const summer20222023StartDate = new Date("2023-04-17");
  const summer20222023EndDate = new Date("2023-07-25");

  const autumn2023StartDate = new Date("2023-09-06");
  const autumn2023EndDate = new Date("2023-12-16");

  const exceptionalDays = closedDates;

  const successfulDays = clubAttendance.map((item) => item.recordedDate);

  const platform = Platform.OS;

  return (
    <ScrollView
      style={{
        flex: 1,
        width: "100%",
        // alignSelf:'center',
        backgroundColor: "transparent",
      }}
      contentContainerStyle={{ 
        alignItems: "stretch"
        
        // platform === "web" ? "center" : "stretch" 
        
        }}>
      {/* <View style={{flex: 1,backgroundColor:'red', width:'auto'}}> */}




      {summer2025StartDate && today > summer2025StartDate?
        <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Summer Term 2024-2025</Text>
        <WeekdayGrid startDate={summer2025StartDate} endDate={summer2025EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View> 
     :null} 
 {spring2025StartDate && today > spring2025StartDate?
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Spring Term 2024-2025</Text>
        <WeekdayGrid startDate={spring2025StartDate} endDate={spring2025EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
   :null} 
 {autumn2024StartDate && today > autumn2024StartDate?
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Autumn Term 2024-2025</Text>
        <WeekdayGrid startDate={autumn2024StartDate} endDate={autumn2024EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      
      :null} 








      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Summer Term 2023-2024</Text>
        <WeekdayGrid startDate={summer2024StartDate} endDate={summer2024EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>

      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Spring Term 2023-2024</Text>
        <WeekdayGrid startDate={spring2024StartDate} endDate={spring2024EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>

      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Autumn Term 2023-2024</Text>
        <WeekdayGrid startDate={autumn2023StartDate} endDate={autumn2023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>




      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Summer Term 2022-2023</Text>
        <WeekdayGrid startDate={summer20222023StartDate} endDate={summer20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Spring Term 2022-2023</Text>
        <WeekdayGrid startDate={spring20222023StartDate} endDate={spring20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Autumn Term 2022-2023</Text>
        <WeekdayGrid startDate={autumn20222023StartDate} endDate={autumn20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>

      {/* </View> */}
    </ScrollView>
  );
};

export default FlexibleLearningHeatMap;

const styles = StyleSheet.create({
  eachTermView: { paddingBottom: 20, backgroundColor: "transparent" },
});
