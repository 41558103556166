import { API, graphqlOperation } from "aws-amplify";
import { View, Text } from "../constants/themedViewAndText";
import React, { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { getLastWeeksDates } from "../functions/getLastWeeksDates";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { BoldText } from "../constants/styledText";
import BremnerIcon from "../../assets/icons/Bremner";
import TomlinsonIcon from "../../assets/icons/Tomlinson";
import RobinsonIcon from "../../assets/icons/Robinson";
import TruemanIcon from "../../assets/icons/Trueman";
import CoachingIcon from "../../assets/icons/Coaching";
import Animated from "react-native-reanimated";

import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp } from "react-native-reanimated";


export const clubAttendanceByRecordedDate = /* GraphQL */ `
  query ClubAttendanceByRecordedDate(
    $recordedDate: AWSDate!
    $opportunityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubAttendanceByRecordedDate(
      recordedDate: $recordedDate

      opportunityID: $opportunityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendanceMark
        recordedDate
        userID
        User {
          firstName
          surname
          preferredSurname
          preferredFirstName
          Enrolments {
            items {
              groupingData1
              groupingData2
              groupingData3
            }
          }
        }
        opportunityID
        Opportunity {
          name
        }
        recordedByID
        RecordedBy {
          title
          surname
          preferredSurname
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

// Define types for the fetched data
interface GroupingData1Counts {
  Bremner: number;
  Tomlinson: number;
  Robinson: number;
  Trueman: number;
}

interface AttendanceData {
  totalAttendanceCount: number;
  groupingData1Counts: GroupingData1Counts;
}

// Helper function to simulate fetching and processing data (replace with actual fetch logic)
async function fetchAndAnalyzeClubAttendances(): Promise<AttendanceData> {
  const { monday, tuesday, wednesday, thursday, friday } = getLastWeeksDates();

  console.log('monday: ', monday);
  console.log('tuesday: ', tuesday);
  console.log('wednesday: ', wednesday);
  console.log('thursday: ', thursday);
  console.log('friday: ', friday);
  

  // Array of dates to query for
  const dates = [monday, tuesday, wednesday, thursday, friday];

  let filteredAttendances = [];

  // Helper function to fetch data for each date
  const fetchAttendancesForDate = async (date) => {
    let nextToken = null;
    let attendances = [];

    do {
      const response = await API.graphql(
        graphqlOperation(clubAttendanceByRecordedDate, {
          recordedDate: date,
          nextToken: nextToken, // Pagination token
        })
      );
      console.log("response for", date, response);

      attendances = [...attendances, ...response.data.clubAttendanceByRecordedDate.items];
      nextToken = response.data.clubAttendanceByRecordedDate.nextToken;
    } while (nextToken !== null); // Keep fetching until there's no more data

    return attendances;
  };

  // Run all queries in parallel
  const allAttendancesPromises = dates.map((date) => fetchAttendancesForDate(date));

  // Wait for all queries to complete
  const results = await Promise.all(allAttendancesPromises);

  // Combine all the results into a single array
  filteredAttendances = results.flat();

  console.log("all filteredAttendances", filteredAttendances);

  // Count attendance for each groupingData2 value
  const groupingData1Counts: GroupingData1Counts = {
    Bremner: 0,
    Tomlinson: 0,
    Robinson: 0,
    Trueman: 0,
  };

  filteredAttendances.forEach((attendance) => {
    const enrolment = attendance.User.Enrolments.items[0]; // Assuming one enrolment per user
    if (enrolment) {
      const groupingData1 = enrolment.groupingData1;
      if (groupingData1Counts.hasOwnProperty(groupingData1)) {
        groupingData1Counts[groupingData1]++;
      }
    }
  });

  // Total attendance count
  const totalAttendanceCount = filteredAttendances.length;

  return {
    totalAttendanceCount,
    groupingData1Counts,
  };
}

// Main Component
const ClubAttendanceComponent: React.FC = () => {
  const { width } = useWindowDimensions();

  const colorScheme = useColorScheme();
  const [attendanceData, setAttendanceData] = useState<AttendanceData>({
    totalAttendanceCount: 0,
    groupingData1Counts: {
      Bremner: 0,
      Tomlinson: 0,
      Robinson: 0,
      Trueman: 0,
    },
  });

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAndAnalyzeClubAttendances();
      setAttendanceData(data);
    };
    fetchData();
  }, []);

  const { totalAttendanceCount, groupingData1Counts } = attendanceData;

  // If total attendance count is 0, do not render the component
  if (totalAttendanceCount === 0) {
    return null; // Or return a placeholder component, like a message saying "No data"
  }

  const componentWidth = width > 720 ? Math.min((width - 680) / 3 + 686, 900) : 340;
  // Calculate the total width of the bar (fixed width for simplicity, e.g., 300px)
  const totalWidth = componentWidth > 400 ? 500 : 300; // The width of the bar
  const totalGroupCount = Object.values(groupingData1Counts).reduce((sum, count) => sum + count, 0);

  // If no attendance was recorded, avoid division by zero
  const barSegments = Object.keys(groupingData1Counts).map((group) => {
    // Assert that `group` is a valid key of GroupingData2Counts
    const groupCount = groupingData1Counts[group as keyof GroupingData1Counts];
    const widthPercentage = totalGroupCount > 0 ? (groupCount / totalGroupCount) * totalWidth : 0;
    const Icon = getIconForGroup(group); // Get the icon for the group
    return { group, groupCount, widthPercentage, Icon };
  });

  return (
    <Animated.View
    entering={FadeIn.duration(500)}
      style={{
        padding: 10,
        margin: 10,
        width: componentWidth,
        maxWidth: 900,
        flexDirection: componentWidth > 400 ? "row" : "column",
        alignSelf: "center",
        height: 170,
        borderRadius: 10,
        justifyContent: "space-between",
        backgroundColor: Colours[colorScheme].background,
      }}>
      <View style={styles.container}>
        {/* Total attendance text */}

        <View
          style={{
            flexDirection: "column",
            backgroundColor: "transparent",
            width: componentWidth > 400 ? 500 : 300,
          }}>
          <View style={{ height: 40, backgroundColor: "transparent", flexDirection: "row", alignItems: "flex-start", borderBottomWidth: 1, borderColor: Colours[colorScheme].edging }}>
            <View
              style={{
                width: 50,
                height: 50,
                left: -8,
                top: -8,
                // alignItems: 'flex-start',
                // justifyContent: "center",
                backgroundColor: "transparent",
              }}>
              <FlexibleLearningIcon color={Colours[colorScheme].textLessEmphasised} />
            </View>
            <BoldText style={{ fontSize: 24, alignSelf: "flex-start", left: -8, marginTop: 2, color: Colours[colorScheme].textLessEmphasised }}>
              {componentWidth > 400 ? "FLEXIBLE LEARNING ATTENDANCE" : "ATTENDANCE"}
            </BoldText>
            {/* <Text style={{ fontWeight: "800", fontSize: 20, marginLeft: 10, color: Colours[colorScheme].textLessEmphasised }}>{currentAcademicYear}</Text> */}
          </View>

          <View style={{ backgroundColor: "transparent", alignItems: "flex-start", justifyContent: "center", width: "90%", paddingVertical: 10 }}>
            <Text style={styles.totalText}>{totalAttendanceCount} Flexible Learning attendance marks were recorded last week.</Text>
          </View>
        </View>

        {/* Bar Chart */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: componentWidth > 400 ? 500 : 300, // The total width of the bar chart
            height: 40, // The height of the bar
            borderRadius: 5, // This will round the left and right edges of the entire bar container
            overflow: "hidden", // Ensures that the rounded corners are clipped correctly
          }}>
          {barSegments.map((segment, index) => (
            <View key={index} style={[styles.barSegment, { width: segment.widthPercentage, backgroundColor: getColorForGroup(segment.group) }]}>
              
            
              
              {segment.widthPercentage < 50 ? null : (

<View style={{backgroundColor:'transparent', height:'100%', width:'100%', alignItems:'center', justifyContent:'center', flexDirection:'row'}}>

                <View
                  style={{
                    // position: "absolute", // Position the icon inside the segment
                    // left: 5, // Adjust the position of the icon within the segment
                    backgroundColor: "transparent",
                    // top: componentWidth > 400 ? 5 : 10,
                    // zIndex: 1, // Ensure icon appears above the background
                    height: componentWidth > 400 ? 30 : 20,
                    width: componentWidth > 400 ? 30 : 20,
                  }}>
                  {segment.Icon}
                </View>
             
        
                <View style={{backgroundColor:'transparent', marginLeft:10}}>
                <Text
                  style={{
                    // position: "absolute",
                    color: Colours[colorScheme].background,
                    // fontWeight: "bold",
                    // fontSize: 14,
                    // textAlign: "left",
                    // marginLeft: componentWidth > 400 ? 80 : 60,
                    // width: "100%",
                  }}>
                  {segment.groupCount}
                </Text></View>
                </View>
                )} 
            </View>
            
          ))}
          
         
        </View>
      </View>
    </Animated.View>
  );
};

// Helper function to get the icon for each group
function getIconForGroup(group: string) {
//   const colorScheme = useColorScheme();

  const icons: Record<string, JSX.Element> = {
    // Bremner: <BremnerIcon color={Colours[colorScheme].background} />,
    // Tomlinson: <TomlinsonIcon color={Colours[colorScheme].background} />,
    // Robinson: <RobinsonIcon color={Colours[colorScheme].background} />,
    // Trueman: <TruemanIcon color={Colours[colorScheme].background} />,

    Bremner: <BremnerIcon color={'white'} />,
    Tomlinson: <TomlinsonIcon color={'white'} />,
    Robinson: <RobinsonIcon color={'white'} />,
    Trueman: <TruemanIcon color={'white'} />,

  };

  return icons[group] || <CoachingIcon />; // Return a default icon if no match
}

// Helper function to get a color for each group
function getColorForGroup(group: string): string {
  const colors: Record<string, string> = {
    Bremner: Colours.palette.bremner,
    Tomlinson: Colours.palette.tomlinson,
    Robinson: Colours.palette.robinson,
    Trueman: Colours.palette.trueman,
  };
  return colors[group] || "#95a5a6"; // Default color if no match
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    backgroundColor: "transparent",
  },
  totalText: {
    // fontSize: 18,
    // marginBottom: 20,
    textAlign: "left",
    flexWrap: "wrap", // Allow the text to wrap if necessary
    maxWidth: "100%", // Ensure it doesn’t overflow
  },
  iconContainer: {},
  barContainer: {},
  barSegment: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",

    position: "relative", // Needed for icon positioning
  },
  barLabel: {},
});

export default ClubAttendanceComponent;
