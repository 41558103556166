import React from "react";
import { Image } from "expo-image";
import { Pressable, StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import Animated from "react-native-reanimated";
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp } from "react-native-reanimated";
const CrewNominationsLink = () => {
  const { width } = useWindowDimensions();
  const colorScheme = useColorScheme();

  // const componentWidth = width > 600 ? (width - 680) / 3 + 686 : 340;
  const componentWidth = width > 720 ? Math.min((width - 680) / 3 + 686, 900) : 340;

  const imageDimensions = componentWidth / 10 > 90 ? 90 : componentWidth / 10;

  const overlap = -componentWidth / 6;

  function handleLinkPress() {
    VibrateOnce();
    WebBrowser.openBrowserAsync("https://docs.google.com/forms/d/e/1FAIpQLScqlXL4yvKeDftWynbMp1Bl9NrCOTeUMToSLVExPqHr2pLm9w/viewform?pli=1");
  }

  return (
    <Pressable onPress={handleLinkPress}>
      <View
        style={{
          padding: 10,
          margin: 10,
          width: componentWidth,
          maxWidth: 900,
          flexDirection: componentWidth > 400 ? "row" : "column",
          alignSelf: "center",
          height: 120,
          borderRadius: 10,
          justifyContent: "space-between",
          backgroundColor: Colours[colorScheme].background,
        }}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            width: componentWidth > 400 ? "70%" : "100%",
            marginTop:componentWidth > 400 ? 0 : 10,
            overflow: "hidden",
            flexDirection: "row",
          }}>
          <Animated.Image entering={BounceIn.delay(200)} style={{ marginLeft: 20, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Teacher.png")} />
          <Animated.Image entering={BounceIn.delay(300)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Rolemodel.png")} />
          <Animated.Image entering={BounceIn.delay(400)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Coach.png")} />
          <Animated.Image entering={BounceIn.delay(500)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Rolemodel.png")} />
          <Animated.Image entering={BounceIn.delay(600)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Manager.png")} />
          <Animated.Image entering={BounceIn.delay(700)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Leader.png")} />
          <Animated.Image entering={BounceIn.delay(800)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Inspiration.png")} />
          <Animated.Image entering={BounceIn.delay(900)} style={{ marginLeft: overlap, width: imageDimensions, height: imageDimensions }} source={require("../../assets/images/Director.png")} />
        </View>
        <View
          style={{
            backgroundColor: "transparent",
            flex: 1,
            alignItems: componentWidth > 400 ? "flex-end" : "center",
            justifyContent: "center",
          }}>
          <Text style={{ color: Colours[colorScheme].text, textAlign: componentWidth > 400 ? "right" : "center", fontSize: 20 }}>Crew Characteristic Badge Nominations</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default CrewNominationsLink;

const styles = StyleSheet.create({});
